import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { AlbaranService } from 'src/app/modules/gestionar-venta/services/albaran.service';
import { FacturaService } from '../../services/factura.service';
import { PrintService } from '../../services/print.service';
import { mergeMap, take } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { SuccessToast } from 'src/app/core/toast/success.toast';
import { Router } from '@angular/router';
import { ErrorToast } from 'src/app/core/toast/error.toast';
import { TurnoService } from 'src/app/core/auth/turno.service';
import { AppConfiguration } from 'src/app/app.configuration';
import { FormControl, FormGroup } from '@angular/forms';
import { PagoService } from 'src/app/modules/venta/services/pago.service';

@Component({
  selector: 'ticketing-confirmar-anulacion-venta',
  templateUrl: './confirmar-anulacion-venta.component.html',
  styleUrls: ['./confirmar-anulacion-venta.component.scss']
})
export class ConfirmarAnulacionVentaComponent implements OnInit {
  @Input() id: string;
  private element: any;
  formBuilder: any;
  pulsado = false;
  constructor(
    private modalService: ModalService,
    private albaranService: AlbaranService,
    private el: ElementRef,
    private facturaService: FacturaService,
    private printService: PrintService,
    private toast: ToastrService,
    private router: Router,
    private config: AppConfiguration,
    private turnoService: TurnoService,
    private pagoService: PagoService
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit() {
    const modal = this;
    if (!this.id) {
      console.error('modal must have an id');
      return;
    }
    document.body.appendChild(this.element);
    this.element.addEventListener('click', function(e: any) {
      if (e.target.className === 'jw-modal') {
        modal.close();
      }
    });
    this.modalService.add(this);
  }

  open(): void {
    this.pulsado = false;
    this.element.style.display = 'block';
    document.body.classList.add('jw-modal-open');
  }

  close(): void {
    this.pulsado = false;
    this.element.style.display = 'none';
    document.body.classList.remove('jw-modal-open');
  }

  async anularVenta() {
    this.albaranService.anularDeshabilitado = true;
    this.pulsado = true;
    let oldNumAlbaran: string = this.albaranService.albaranValue.NumAlbaran;
    let oldNumTPVID: string = this.albaranService.albaranValue.TPVId;
    // 
    if (this.albaranService.albaranValue.TienePagoCC === '1') {
      this.albaranService
        .generarIdentificadores(
          this.turnoService.turnoValue.TpvId,  //this.albaranService.albaranValue.TPVId,
          this.albaranService.albaranValue.Prefijo
        )
        .subscribe((data: any) => {
          this.albaranService
            .anularAlbaranTarjeta(
              data.DatosResult.Identificador,
              data.DatosResult.NumAlbaran
            )
            .subscribe(item => {
              // 
              if (item.AlbaranId && item.Facturado === '0') {
                this.albaranService
                  .refrescarAlbaran(
                    this.albaranService.albaranValue.NumAlbaran
                  )
                  .subscribe(() => {
                    // manieva 11818
                    // refactoreo funcion
                    this.refrescarAlbaranRemoto();
                    // this.albaranService.anulacionTotal.next(false);
                    // this.close();
                    // this.router.navigate(['gestionar-venta']);
                  });
              } else if (item.AlbaranId && item.Facturado === '1') {
                // 
                this.facturaService.impresionFactura(item).subscribe((res) => {
                    this.albaranService
                      .refrescarAlbaran(
                        this.albaranService.albaranValue.NumAlbaran
                      )
                      .subscribe(() => {
                        // manieva 11818
                        // refactoreo funcion
                        this.refrescarAlbaranRemoto();
                        // this.albaranService.anulacionTotal.next(false);
                        // this.close();
                        // this.router.navigate(['gestionar-venta']);
                      });
                })

                // this.facturaService
                // .pdfFactura(item)
                //      .subscribe(async (facturas: any) => {
                //   if (facturas.DatosResult) {
                //     await this.printService.printFacturaPDF(
                //       facturas.DatosResult
                //       );                       
                //       this.albaranService
                //       .refrescarAlbaran(
                //         this.albaranService.albaranValue.NumAlbaran
                //       )
                //       .subscribe(() => {
                //         // manieva 11818
                //         // refactoreo funcion
                //         this.refrescarAlbaranRemoto();
                //         // this.albaranService.anulacionTotal.next(false);
                //         // this.close();
                //         // this.router.navigate(['gestionar-venta']);
                //       });
                //   }
                // });
              }
                if(item.NumAlbaran)
                  {
                    this.pagoService.impresionJustificante(item.NumAlbaran, this.albaranService.albaranValue.NumVenta).subscribe();
                    //this.imprimirJustificanteDevolucion (item.NumAlbaran, 'TOTAL');
                    this.albaranService.refrescarAlbaranConTPVId(oldNumAlbaran, oldNumTPVID).subscribe();
                  }
              
            });
        });
    } else {
      const response: any = await this.albaranService.anularAlbaran();
      if (response.AlbaranId && response.Facturado === '0') {
        this.albaranService
          .refrescarAlbaran(this.albaranService.albaranValue.NumAlbaran)
          .subscribe(() => {
            // manieva 11818
            // refactoreo funcion
            this.refrescarAlbaranRemoto();
            // this.albaranService.anulacionTotal.next(false);
            // this.close();
            // this.router.navigate(['gestionar-venta']);
          });
      } else if (response.AlbaranId && response.Facturado === '1') {
        this.facturaService
          .impresionFactura(response)
          .subscribe(() => {
            this.albaranService
              .refrescarAlbaran(this.albaranService.albaranValue.NumAlbaran)
              .subscribe(() => {
                this.refrescarAlbaranRemoto();
              });
          });          

        // this.facturaService
        //     .pdfFactura(response)                
        //     .subscribe(async (facturas: any) => {
        //       if (facturas.DatosResult) {
        //         await this.printService.printFacturaPDF(facturas.DatosResult);               
        //         this.albaranService
        //       .refrescarAlbaran(
        //         this.albaranService.albaranValue.NumAlbaran
        //       )
        //       .subscribe(() => {
        //         // manieva 11818
        //         // refactoreo funcion
        //         this.refrescarAlbaranRemoto();
        //         // this.albaranService.anulacionTotal.next(false);
        //         // this.close();
        //         // this.router.navigate(['gestionar-venta']);
        //       });
        //       }
        //     });                  
      }
      if(response.NumAlbaran)
      {
        this.pagoService.impresionJustificante(response.NumAlbaran, this.albaranService.albaranValue.NumVenta).subscribe();
        //this.imprimirJustificanteDevolucion (response.NumAlbaran, 'TOTAL');
        this.albaranService.refrescarAlbaranConTPVId(oldNumAlbaran, oldNumTPVID).subscribe();
      }
      // manieva 11818
      // el back devuelve mensaje, a este componente nos llega un objeto vacio
      // cuando se produce error
      if(Object.keys(response).length ===  0) 
        this.refrescarAlbaranRemoto();
    }
  }
  
  refrescarAlbaranRemoto() {

    this.albaranService
      .refrescarAlbaran(this.albaranService.albaranValue.NumAlbaran)
      .subscribe(() => {
        this.albaranService.anulacionTotal.next(false);
        this.close();
        this.router.navigate(['gestionar-venta']);
      });

  }

  isAceptarDisabled() {
    return this.albaranService.anularDeshabilitado;
  }

  imprimirJustificanteDevolucion (NumAlbaran: string, tipo: string){
    //TODO  Yaribel 20201124 mejorar mapear el objeto y no usar refrescarAlbaran
    this.albaranService.refrescarAlbaranConTPVId(NumAlbaran, this.turnoService.turnoValue.TpvId).subscribe(res =>{
    //this.albaranService.refrescarAlbaran(NumAlbaran).subscribe(res =>{
      if(res)
      {
        const albaranValue = this.albaranService.albaranValue;
        const NombreTPV = this.albaranService.albaranValue.NombreTPV;
        const IdTPV = this.albaranService.albaranValue.IdTPV;
        if(albaranValue.AlbaranFOPs.find(x=> x.FOP == 'CA')) { this.pagoService.abrirCajon(); }

        this.pagoService.datosCabecera().pipe(take(1)).subscribe(data => {
        switch (tipo){
          case 'TOTAL' :
            this.printService.printRefundAlbaran({ 
              datos: albaranValue,
              NombreTPV: NombreTPV,
              IdTPV: IdTPV
            }, data);
            break;
            case 'PARCIAL':
              this.printService.printPartialRefund({
                datos: albaranValue
              
              },data);
              break;
        }


    });
          
  }
    });

}
}
