import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as moment from 'moment';
import { BehaviorSubject, Observable, forkJoin, throwError } from 'rxjs';
import { AppConfiguration } from 'src/app/app.configuration';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ConfigurationService } from '../conf';
import { environment } from 'src/environments/environment';
import { PagoService } from 'src/app/modules/venta/services/pago.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorToast } from '../toast/error.toast';
import { TranslateService } from 'src/app/shared/services/translate.service';

@Injectable({
  providedIn: 'root',
})
export class TurnoService {
  private turnoSubject: BehaviorSubject<any>;
  public turno: Observable<any>;

  private cambioSubject: BehaviorSubject<any>;
  public cambio: Observable<any>;

  private cajaSubject: BehaviorSubject<any>;
  public caja: Observable<any>;

  private efectivoTurnoSubject: BehaviorSubject<any>;
  public efectivoTurno: Observable<any>;

  private cajasTurnoSubject: BehaviorSubject<any>;
  public cajasTurno: Observable<any>;

  window = window as any;

  constructor(
    private http: HttpClient,
    private config: AppConfiguration,
    private configuration: ConfigurationService,
    private toastService: ToastrService,
    private translateService: TranslateService
  ) {
    this.turnoSubject = new BehaviorSubject(undefined);
    this.turno = this.turnoSubject.asObservable();

    this.cambioSubject = new BehaviorSubject(undefined);
    this.cambio = this.cambioSubject.asObservable();

    this.cajasTurnoSubject = new BehaviorSubject(undefined);
    this.cajasTurno = this.cajasTurnoSubject.asObservable();

    this.cajaSubject = new BehaviorSubject(undefined);
    this.caja = this.cajaSubject.asObservable();

    this.efectivoTurnoSubject = new BehaviorSubject(undefined);
    this.efectivoTurno = this.efectivoTurnoSubject.asObservable();
  }

  public get cajaValue() {
    return this.cajaSubject.value;
  }

  setCajaValue(caja) {
    this.cajaSubject.next(caja);
  }

  public get efectivoTurnoValue() {
    return this.efectivoTurnoSubject.value;
  }

  setEfectivoTurnoValue(efectivoTurno) {
    this.efectivoTurnoSubject.next(efectivoTurno);
    this.setCambioValue(efectivoTurno);
  }

  public get turnoValue() {
    return this.turnoSubject.value;
  }

  setTurnoValue(turno) {
    this.turnoSubject.next(turno);
  }

  public get cambioValue() {
    return this.cambioSubject.value;
  }

  setCambioValue(valor) {
    if (valor) {
      let cambio = {
        cambioInicial: valor.cambioInicial || valor.ImporteInicial || '0',
        cambioIntroducido:
          valor.cambioIntroducido || valor.ImporteIntroducido || '0',
        ventasEfectivo: valor.ventasEfectivo || '0',
        cambioRetirado: valor.CambioRetirado || valor.ImporteRetirado || '0',
        cambioResultante: valor.ImporteTotalCaja || undefined,
      };

      this.recalcularCambioResultante(cambio);
    } else {
      this.cambioSubject.next(valor);
    }
  }

  public get cajasValue() {
    return this.cajasTurnoSubject.value;
  }

  setCajasValue(cajas) {
    return this.cajasTurnoSubject.next(cajas);
  }

  recalcularCambioResultante(cambio) {
    if (cambio.cambioResultante) {
    } else {
      cambio.cambioResultante = Number.parseFloat(
        cambio.cambioInicial.replace(',', '.')
      );
      +Number.parseFloat(cambio.cambioIntroducido.replace(',', '.'));
      +Number.parseFloat(cambio.ventasEfectivo.replace(',', '.'));
      +Number.parseFloat(cambio.cambioRetirado.replace(',', '.'));
    }

    this.cambioSubject.next(cambio);
  }

  getTurnoAbierto(
    idUsuario: string,
    idTpv: string = '0',
    fecha = moment().format('YYYY/MM/DD')
  ) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      TpvId: idTpv,
      UsuarioId: idUsuario,
      FechaBusqueda: fecha,
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/VentaTaquilla/GetTurnoAbierto`,
      body
    );
  }

  insertarTurno(
    idUsuario: string,
    idDivisa: string,
    idTpv: string,
    bolsaDeCambio: string,
    fecha = moment().format('YYYY/MM/DD HH:mm:ss')
  ) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      UsuarioId: idUsuario,
      FechaHoraInicio: fecha,
      DivisaId: idDivisa,
      TpvId: idTpv,
      TurnoCerrado: 0,
      BolsaCambio: bolsaDeCambio,
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/VentaTaquilla/InsResumenTurno`,
      body
    );
  }

  insertarCajasTurno(
    idTurno: number,
    cantidad: number,
    idDivisa: number,
    mediosDePago: string[],
    cambioDivisa: number
  ) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const peticiones = mediosDePago.map((medioDePago) => {
      const body = {
        ConexionIacpos: conexionId,
        TurnoId: idTurno,
        Cantidad: cantidad,
        DivisaId: idDivisa,
        MedioPago: medioDePago,
        CambioDivisa: cambioDivisa,
        DivisaRefId: idDivisa,
      };
      return this.http.post(
        `${this.config.getConfig('API_URL')}api/VentaTaquilla/InsCajasTurno`,
        body
      );
    });
    return forkJoin(peticiones);
  }

  // tslint:disable-next-line:max-line-length
  insertarOperacionesDeCaja(
    idUsuario: number,
    idTurno: number,
    comentario: string,
    cantidad: number,
    idDivisa: number,
    tipoOperacion: string,
    bolsaCambio: string,
    fecha = moment().format('YYYY/MM/DD HH:mm:ss')
  ) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      UsuarioId: idUsuario,
      TurnoId: idTurno,
      Comentario: comentario,
      Cantidad: cantidad,
      DivisaId: idDivisa,
      TipoOperacion: tipoOperacion,
      BolsaCambio: bolsaCambio,
      FechaHora: fecha,
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/VentaTaquilla/InsOperacionesCaja`,
      body
    );
  }

  getCajasTurno(idTurno: any, idDivisa: number, mediosDePago: string[]) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const peticiones = mediosDePago.map((medioDePago) => {
      const body = {
        ConexionIacpos: conexionId,
        TurnoId: idTurno,
        DivisaId: idDivisa,
        FOP: medioDePago,
      };
      return this.http.post(
        `${this.config.getConfig('API_URL')}api/VentaTaquilla/GetCajasTurno`,
        body
      );
    });
    return forkJoin(peticiones);
  }

  getOperacionesCajaMetalico(idTurno: number) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      TurnoId: idTurno,
    };
    return this.http.post(
      `${this.config.getConfig(
        'API_URL'
      )}api/VentaTaquilla/GetOperacionesCajaMetalicoTurno`,
      body
    );
  }

  finalizarTurno(
    idTurno: number,
    totalCaja: number,
    bolsaCambio: string,
    fecha = moment().format('YYYY/MM/DD HH:mm:ss')
  ) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      TurnoId: idTurno,
      TotalCajaManual: totalCaja,
      BolsaCambio: bolsaCambio,
      FechaHoraFin: fecha,
    };
    return this.http.post(
      `${this.config.getConfig(
        'API_URL'
      )}api/VentaTaquilla/UpdResumenTurnosFin`,
      body
    );
  }

  abrirCaja(idTpv, idUser, idDivisa, idDivisaRef, cambioInicial, desglose) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      TpvId: idTpv,
      FechaBusqueda: moment().format('YYYY/MM/DD HH:mm:ss'),
      UsuarioId: idUser,
      DivisaId: idDivisa,
      DivisaRefId: idDivisaRef,
      BolsaCambio: '',
      ImporteInicial: cambioInicial.replace(',', '.'),
      CambiosDesglosados: desglose,
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/VentaTaquilla/CajaApertura`,
      body
    );
  }

  consultarEfectivoCaja() {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      CajaId: this.cajaValue.CajaId,
    };
    return this.http.post(
      `${this.config.getConfig(
        'API_URL'
      )}api/VentaTaquilla/CajaConsultaEfectivo`,
      body
    );
  }

  arqueoParcial(importe, comentario) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      CajaId: this.cajaValue.CajaId,
      Fecha: moment().format('YYYY/MM/DD HH:mm:ss'),
      UsuarioId: this.turnoValue.UsuarioId,
      DivisaId: this.turnoValue.DivisaId,
      Importe: importe,
      Comentario: comentario,
      TurnoId: this.turnoValue.pkid,
      tipoOperacion: 'AP',
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/VentaTaquilla/CajaGestion`,
      body
    );
  }

  retirarEfectivo(importe, comentario, desglose?) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      CajaId: this.cajaValue.CajaId,
      Fecha: moment().format('YYYY/MM/DD HH:mm:ss'),
      UsuarioId: this.turnoValue.UsuarioId,
      DivisaId: this.turnoValue.DivisaId,
      Importe: importe,
      Comentario: comentario,
      TurnoId: this.turnoValue.pkid,
      tipoOperacion: 'CO',
      CambiosDesglosados: desglose || [],
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/VentaTaquilla/CajaGestion`,
      body
    );
  }

  ingresarEfectivo(importe, comentario, desglose?) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      CajaId: this.cajaValue.CajaId,
      Fecha: moment().format('YYYY/MM/DD HH:mm:ss'),
      UsuarioId: this.turnoValue.UsuarioId,
      DivisaId: this.turnoValue.DivisaId,
      Importe: importe,
      Comentario: comentario,
      TurnoId: this.turnoValue.pkid,
      tipoOperacion: 'CH',
      CambiosDesglosados: desglose || [],
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/VentaTaquilla/CajaGestion`,
      body
    );
  }

  cerrarSesion() {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      CajaId: this.cajaValue.CajaId,
      Fecha: moment().format('YYYY/MM/DD HH:mm:ss'),
      UsuarioId: this.turnoValue.UsuarioId,
      DivisaId: this.turnoValue.DivisaId,
      Importe: '',
      Comentario: '',
      TurnoId: this.turnoValue.pkid,
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/VentaTaquilla/CajaGestion`,
      body
    );
  }

  cerrarCaja(importe, comentario, desglose?) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      CajaId: this.cajaValue.CajaId,
      Fecha: moment().format('YYYY/MM/DD HH:mm:ss'),
      UsuarioId: this.turnoValue.UsuarioId,
      DivisaId: this.turnoValue.DivisaId,
      Importe: importe,
      Comentario: comentario,
      TurnoId: this.turnoValue.pkid,
      tipoOperacion: 'CC',
      CambiosDesglosados: desglose || [],
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/VentaTaquilla/CajaGestion`,
      body
    );
  }

  generarInformeArqueo(cajaId) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      CajaId: cajaId,
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/Informes/CajaInformeArqueo`,
      body
    );
  }

  generarInformeCierre(cajaId) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      CajaId: cajaId,
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/Informes/CajaInformeCierre`,
      body
    );
  }

  /**
   * Genera un informe de cierre de caja para un TPV específico.
   *
   * Este método obtiene un token de autenticación, lo usa para realizar una solicitud
   * HTTP a un API externo y recibe un informe de cierre en formato PDF binario.
   * Dependiendo de la capacidad del sistema, el PDF se imprime directamente o se descarga.
   *
   * @param {string} cajaId - El identificador de la caja.
   * @param {string} tpvId - El identificador del TPV.
   * @returns {Observable<any>} - Un Observable que maneja la operación asíncrona.
   */
  generarInformeCierreV2(cajaId: string, tpvId: string, cantidadImpresiones: number) {
    return this.createToken().pipe(
      switchMap(async (token) => {
        const conexionId = JSON.parse(localStorage.getItem('conexionId'));
        const idioma = 'es-ES';
        const claveCentro = localStorage.getItem('claveCentro') || '';

        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token['__zone_symbol__value']}`,
        };

        const params = new HttpParams()
          .set('conexionId', conexionId)
          .set('cajaId', cajaId)
          .set('claveCentro', claveCentro)
          .set('tpvId', tpvId)
          .set('idioma', idioma);

        const url = `${this.config.getConfig(
          'API_URL_TAQUILLA'
        )}/api/InformeCaja/Cierre`;

        await this.getToPromise(url, headers, params).then((data) => {
          if (data && data.datos) {
            if(cantidadImpresiones > 0){
            for (let i = 0; i < cantidadImpresiones; i++) {
              if (this.canPrintPDFWithExternal())
                this.window.external.imprimirPDFBinario(
                  data.datos,
                  'documento'
                );
              if (!this.canPrintPDFWithExternal())
                this.downloadPDF(
                  data.datos,
                  `${this.translateService.instant('cierre')}.pdf`
                );
              }
            } 
          }
        });
      }),
      catchError((error) => {
        this.toastService.error(
          'Error',
          `Ha ocurrido un error avise a su encargado`,
          {
            toastComponent: ErrorToast,
            timeOut: 5000,
          }
        );
        return throwError(error);
      })
    );
  }

  /**
   * Prints a PDF from a base64 string. If external printing is available,
   * it uses the external printing function; otherwise, it downloads the PDF.
   *
   * @param base64 - The base64 encoded string of the PDF.
   * @param fileName - The name of the file to be downloaded. Defaults to 'ticket.pdf'.
   */
  printPDFWithBase64(base64: string, fileName: string = 'ticket.pdf') {
    if (this.canPrintPDFWithExternal()) {
      this.window.external.imprimirPDFBinario(base64, 'ticket');
      //this.imprimirPDFBinario(base64);
    } else {
      this.downloadPDF(base64, fileName);
    }
  }

  /**
   * Creates a PDF file from a base64 string and triggers a download.
   *
   * @param base64 - The base64 encoded string of the PDF.
   * @param fileName - The name of the file to be downloaded.
   */
  downloadPDF(base64: string, fileName: string) {
    const blob = this.createFilePdf(base64, 'application/pdf');
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = fileName;
    link.click();
    URL.revokeObjectURL(blobUrl);
  }

  /**
   * Crea un objeto Blob a partir de una cadena base64 y un tipo de archivo.
   * @param base64 La cadena en formato base64 que representa los datos del archivo.
   * @param type El tipo MIME del archivo, por ejemplo: 'application/pdf'.
   * @returns Un objeto Blob que representa el archivo generado.
   */
  createFilePdf(base64: string, type: string): Blob {
    const binaryData = atob(base64);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i) & 0xff;
    }
    return new Blob([arrayBuffer], { type });
  }

  /**
   * Checks if the external printing function is available.
   *
   * @returns A boolean indicating whether the external printing function is available.
   */
  canPrintPDFWithExternal(): boolean {
    return (
      this.window.external &&
      typeof this.window.external.imprimirPDFBinario === 'function'
    );
  }

  /**
   * Realiza una solicitud HTTP GET y devuelve una promesa que se resuelve con los datos de la respuesta.
   * @param url La URL a la que se realizará la solicitud GET.
   * @param headers Los encabezados HTTP que se enviarán con la solicitud.
   * @returns Una promesa que se resuelve con los datos de la respuesta HTTP.
   */
  getToPromise(url: string, headers, params): Promise<any> {
    return this.http
      .get(url, { headers: headers, params, observe: 'response' }) // 'observe' para obtener la respuesta completa
      .pipe(
        map((res: any) => {
          if (!res) {
            throw new Error('Response undefined');
          }
          return res.body;
        }),
        catchError((error) => {
          const statusMatch = error.match(/Error:\s(\d+)/);
          const statusCode = statusMatch ? parseInt(statusMatch[1], 10) : null;
          const customError = {
            status: statusCode,
            message: error.message,
          };
          return throwError(customError);
        })
      )
      .toPromise(); // Convertir el observable en una promesa
  }

  /**
   * Encodes a base64 string to a binary string using window.btoa.
   *
   * @param base64 - The base64 encoded string.
   * @returns The binary string encoded from the base64 input.
   */
  imprimirPDFBinario(base64: string): string {
    return window.btoa(base64);
  }

  createToken() {
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
    };
    const bodyToken = {
      usuario: environment.API_TOKEN_USER,
      contrasena: environment.API_TOKEN_PASSWORD,
    };
    return this.http
      .post(`${this.config.getConfig('API_URL_TAQUILLA')}/Token`, bodyToken, {
        headers: headers,
      })
      .pipe(
        map(async (res: any) => {
          if (!res.token) {
            this.toastService.error('', res.Mensajes[0].DescripcionMensaje, {
              toastComponent: ErrorToast,
              timeOut: 5000,
            });
            return res;
          }
          return res.token;
        })
      );
  }

  eliminaReservaAforoCompleto() {
    const identificadorUnico = this.configuration.datosTPVPathValue.pkId;
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      SesionInternetId: identificadorUnico,
      Accion: 0,
      RecintosSesionesId: '',
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/CancelarReservaAforo`,
      body
    );
  }

  getDatosTPVPath() {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    //obtenemos el tpvPath de la aplicacion
    this.configuration.getTPVPathLocal();
    //asignamos el tpv obtenido desde el servicio
    const config = this.configuration.tpvPathLocalValue;
    //const config = this.config.getConfig('TPV_PATH');
    return this.http
      .post(
        `${this.config.getConfig('API_URL')}api/Configuracion/GetDatosTPVPath`,
        {
          ConexionIacpos: conexionId,
          VMPath: config,
        }
      )
      .pipe(
        map((TPVPath: any) => {
          this.configuration.setDatosTPVPathValue(TPVPath.DatosResult);
          // return TPVPath.DatosResult;
        })
      );
  }

  updateTPVEnUso(vmPath: string, enUso: string): Observable<any> {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      VMPath: vmPath,
      EnUso: enUso ? enUso : 0,
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/UpdTPVEnUso`,
      body
    );
  }

  getTPVenUso() {
    if (!this.configuration.tpvFisicoValue) {
      this.configuration
        .getTPVEnUso(this.configuration.tpvPathLocalValue)
        .subscribe();
    }
  }
}
