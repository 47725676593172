import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeWhile } from 'rxjs/operators';
import { ConfigurationService } from 'src/app/core';
import { ModalService } from 'src/app/shared/services/modal.service';
import { pais, comunidad, ciudad } from '../../models/procedencias.model';
import { RecintosService } from '../../services/recintos.service';
import { VentaService } from '../../services/venta.service';
// manieva se producia una referencia circular
// import { StepperNavigationService, EntradasService } from '../..';
import { StepperNavigationService } from '../../services/stepper-navigation.service';
import { EntradasService } from '../../services/entradas.service';
import { ProcedenciaService } from '../../services/procedencia.service';

@Component({
  selector: 'ticketing-selector-procedencia',
  templateUrl: './selector-procedencia.component.html',
  styleUrls: ['./selector-procedencia.component.scss']
})
export class SelectorProcedenciaComponent implements OnInit {
  @ViewChild('inputBusqueda') inputBusqueda: ElementRef;
  public paises: pais[];
  private comunidades: comunidad[];
  private comunidadesFiltradas: comunidad[];
  private ciudades: ciudad[];
  private ciudadesFiltradas: ciudad[];
  private alive = true;
  public pasoProcedenciaComunidad = false;
  public pasoProcedenciaCiudad = false;
  public pasoProcedenciaCPOS = false;
  public paisSeleccionado;
  private comunidadSeleccionada;
  private ciudadSeleccionada;
  public aplicar: number = 0; //0 = venta, 1= entrada
  public tituloProcedencia = "Procedencia";
  private nombreEntradaActual = "";
  private listadoProductos = [];
  private indexProducto = 0;
  private procedenciaCPOSForm: FormGroup;
  private valProcedenciaCPOS;
  public abrirBuscarPais = false;
  public paisSeleccionadoEnBusqueda = false;
  private numeroPaisesFila = 12;
  public valBusquedaPais = "";
  private busquedaPaisForm: FormGroup;

  constructor(    
    private ventaService: VentaService,
    private modalService: ModalService,
    private configurationService: ConfigurationService,
    private navigationService: StepperNavigationService,
    private recintosService: RecintosService,
    private entradasService: EntradasService,
    private configuration: ConfigurationService,
    private procedenciaService: ProcedenciaService,
    private formBuilder: FormBuilder,
    private http: HttpClient) { }

  // compareBOrdenVisualizacion(a, b) {
  //   return b.OrdenVisualizacion.localeCompare(a.OrdenVisualizacion);
  // }

  ngOnInit() {
    //Cargar Procedencias
    //Paises
    this.navigationService.procedenciasService
      .getPaises()
      .pipe(takeWhile(() => this.alive))
      .subscribe((res: any) => {
        // ;
        if (res.DatosPaises) {
          // seleccionable
          //.sort((a, b) => ((a.OrdenVisualizacion == null||a.OrdenVisualizacion == "0" ||a.OrdenVisualizacion.trim() == ""? "999999": a.OrdenVisualizacion) > (b.OrdenVisualizacion == null||b.OrdenVisualizacion == "0" ||b.OrdenVisualizacion.trim() == ""? "999999": b.OrdenVisualizacion)) ? 1 : -1);
          this.paises = res.DatosPaises
            .filter(a => a.Seleccionable == "1");
            //.sort(this.compareBOrdenVisualizacion);   
          // 
        }
      });
      //Comunidades
      this.navigationService.procedenciasService
      .getComunidades()
      .pipe(takeWhile(() => this.alive))
      .subscribe((res: any) => {
        // ;
        if (res.DatosResult && res.DatosResult.DatosComunidades) {
          // seleccionable
          this.comunidades = res.DatosResult.DatosComunidades.filter(a => a.Seleccionable == "1");//.sort((a, b) => ((a.OrdenVisualizacion == null||a.OrdenVisualizacion == "0" ||a.OrdenVisualizacion.trim() == ""? "999999": a.OrdenVisualizacion) > (b.OrdenVisualizacion == null||b.OrdenVisualizacion == "0" ||b.OrdenVisualizacion.trim() == ""? "999999": b.OrdenVisualizacion)) ? 1 : -1);  
          // 
        }
      });
      //Ciudades
      this.navigationService.procedenciasService
      .getCiudades()
      .pipe(takeWhile(() => this.alive))
      .subscribe((res: any) => {
        // ;
        if (res.DatosResult && res.DatosResult.DatosCiudades) {
          // seleccionable
          this.ciudades = res.DatosResult.DatosCiudades.filter(a => a.Seleccionable == "1");//.sort((a, b) => ((a.OrdenVisualizacion == null||a.OrdenVisualizacion == "0" ||a.OrdenVisualizacion.trim() == ""? "999999": a.OrdenVisualizacion) > (b.OrdenVisualizacion == null||b.OrdenVisualizacion == "0" ||b.OrdenVisualizacion.trim() == ""? "999999": b.OrdenVisualizacion)) ? 1 : -1);  
          // 
        }
      });
      let numProducto = 0;
      let numEntradaProducto = 0;
      let numEntradaCantidad = 0;
      let totalProductos = 1;
      const procendencias = this.procedenciaService.getprocedenciasValue() as Array<any>;
      //Controlar que cuenteVisitante
      this.ventaService.carritoValue.entradas.forEach(entrada => {
        if (entrada.entrada.TipoProducto === '1') {
            numEntradaCantidad = 0;
            for (let index = 0; index < entrada.cantidad; index++) {
              this.listadoProductos.push(
                {
                  nombre: entrada.entrada.NombrePantalla,
                  completado: false,
                  data: {
                    numeroProducto: numProducto,
                    numEntradaProducto: 0,
                    numEntradaCantidad: numEntradaCantidad
                  },
                  procedencias: {
                    pais: null,
                    comunidad: null,
                    ciudad:null
                  }
                }
              );

              numEntradaCantidad = numEntradaCantidad +1;
              totalProductos = totalProductos +1;
            }
  
            //if(this.nombreEntradaActual != undefined && this.nombreEntradaActual == "") this.nombreEntradaActual = entrada.entrada.NombrePantalla;
            //numProducto = numProducto +1;         

        } else if (entrada.entrada.TipoProducto === '2') {
          //.entrada.PromocionEntradas.
          numEntradaProducto= 0;
          for (let index = 0; index < entrada.cantidad; index++) {
          //si alguna de las entradas tiene CuentaVisitante
          entrada.entrada.PromocionEntradas.forEach(entradaPromocion => {
            numEntradaCantidad = 0;
            if(entradaPromocion.CuentaVisitante == '1')
            {
              for (let index = 0; index < entradaPromocion.NumeroEntradas; index++) {
                this.listadoProductos.push(
                  {
                    nombre: entradaPromocion.NombreTipo,
                    completado: false,
                    data: {
                      numeroProducto: numProducto,
                      numEntradaProducto: numEntradaProducto,
                      numEntradaCantidad: numEntradaCantidad
                    },
                    procedencias: {
                      pais: null,
                      comunidad: null,
                    ciudad:null
                    }
                  }
                );
                numEntradaCantidad = numEntradaCantidad +1;
                totalProductos = totalProductos +1;
              }
            }
          });
          numEntradaProducto = numEntradaProducto +1;
        }
          //if(this.nombreEntradaActual != undefined && this.nombreEntradaActual == "") this.nombreEntradaActual = entrada.entrada.NombrePantalla;
        }
        numProducto = numProducto +1;
      });
      // 
      this.procedenciaCPOSForm = this.formBuilder.group({
        procedenciaCPOS: [
          '',
          Validators.compose([
            Validators.pattern('^[z0-9]')
          ])
        ]
      });
      this.busquedaPaisForm = this.formBuilder.group({
        busquedaPais: ['']
      });
      //si listadoProductos esta vacio nos saltamos esto, si no cargamos la primera
      if (this.listadoProductos.length >0)
      {
        this.nombreEntradaActual = this.listadoProductos[0].nombre + ' ' + '1/' + this.listadoProductos.length.toString();
      }
      else
      {
        this.navigationService.next();
      }

  }
  ngOnDestroy() {
    this.alive = false;
  }


  /* TODO 


  Documentacion de los valores de la procedencia
      tbTPVS.iTipoProcedencia
      siendo:
      tipo de Procedencia 0/Null=> No se pide, 1 => Solo Pais (primer nivel), 2=> Pais+Comunidad ,[3=> Pais+Comunidad+Provincia / 4=> Pais+Comunidad+Ciudad /5=>Pais+Comunidad+CodigoPostal]
      El tercer nivel es excluyente, se pedirá provincia O Ciudad O Código postal, pero no combinaciones
  */
  seleccionarPais(pais, tipo, codebehind?:boolean)
  {
    if(tipo==2)
    {
      //this.paisSeleccionado = this.paises.find(element => element.NombrePais == pais);
      this.paisSeleccionado = pais;
      this.paisSeleccionadoEnBusqueda = true;
      this.abrirBuscarPais = false;
    }
    else
    {
      this.paisSeleccionado = pais;
    }
    //Comprobamos si tenemos hijos y el paso siguiente esta configurado
    //this.configuration.datosTPVPathValue.TipoProcedencia !== "0"
    this.comunidadesFiltradas = this.comunidades.filter(comunidad => comunidad.idPais == this.paisSeleccionado.idPais);
    if(this.comunidadesFiltradas.length >0 && this.configuration.datosTPVPathValue.TipoProcedencia > "1") 
    {
      this.pasoProcedenciaComunidad = true;
    }
    else
    {
      this.pasoProcedenciaComunidad = false;
      this.pasoProcedenciaCPOS = false
      this.pasoProcedenciaCiudad = false;
      this.comunidadSeleccionada = null;
      this.ciudadSeleccionada = null;
    }
    //Metemos en listado productos
    if(this.aplicar == 1)
    {
      this.listadoProductos[this.indexProducto].procedencias.pais = this.paisSeleccionado;
      this.listadoProductos[this.indexProducto].completado = !this.pasoProcedenciaComunidad;
    }
    else
    {
      this.listadoProductos.forEach(producto=> {
        producto.procedencias.pais = this.paisSeleccionado;
        producto.completado = !this.pasoProcedenciaComunidad;
      })
    }
    this.navigationService.procedenciasService.setProcedenciasValue(this.listadoProductos);
    if (!codebehind && !this.pasoProcedenciaComunidad)
    {
      this.nextProcedencia();
    }
  }

  seleccionarComunidad(comunidad, tipo, codebehind?:boolean)
  {
    this.comunidadSeleccionada = comunidad;
    //Comprobamos si tenemos hijos y el paso siguiente esta configurado
    if (this.configuration.datosTPVPathValue.TipoProcedencia == "3")
    {
      this.ciudadesFiltradas = this.ciudades.filter(ciudad => ciudad.idPais == this.comunidadSeleccionada.idPais && ciudad.codComunidad == this.comunidadSeleccionada.CodComunidad && ciudad.CodProvincia && ciudad.CodProvincia.trim() != "");
    }
    else if (this.configuration.datosTPVPathValue.TipoProcedencia == "4")
    {
      this.ciudadesFiltradas = this.ciudades.filter(ciudad => ciudad.idPais == this.comunidadSeleccionada.idPais && ciudad.codComunidad == this.comunidadSeleccionada.CodComunidad && ciudad.CodCiudad && ciudad.CodCiudad.trim() != "");
    }
    else
    {
      this.ciudadesFiltradas = [];
    }
    //Filtramos tambien por Tipoprocedencia
    if(this.ciudadesFiltradas.length >0 && this.configuration.datosTPVPathValue.TipoProcedencia > "2") 
    {
      this.pasoProcedenciaCiudad = true;
    }
    else if (this.configuration.datosTPVPathValue.TipoProcedencia == "5")
    {
      this.pasoProcedenciaCPOS = true;
    }
    else
    {
      this.pasoProcedenciaCPOS = false
      this.pasoProcedenciaCiudad = false;
      this.ciudadSeleccionada = null;
    }
    //Metemos en listado productos
    if(this.aplicar == 1)
    {
      this.listadoProductos[this.indexProducto].procedencias.comunidad = this.comunidadSeleccionada;
      //manieva
      //completado para este caso es opcional
      //dado que siempre es obligatoria la pregunta para poder pasar el step
      //apenas selecciona  la comunidad ya deja el seteo en true
      if (this.configuration.datosTPVPathValue.TipoProcedencia == "3")
        this.listadoProductos[this.indexProducto].completado = true;
      else
        this.listadoProductos[this.indexProducto].completado = !(this.pasoProcedenciaCiudad || this.pasoProcedenciaCPOS);
    }
    else
    {
      this.listadoProductos.forEach(producto=> {
        producto.procedencias.comunidad = this.comunidadSeleccionada
        //manieva
        //completado para este caso es opcional
        //dado que siempre es obligatoria la pregunta para poder pasar el step
        //apenas selecciona  la comunidad ya deja el seteo en true
        if (this.configuration.datosTPVPathValue.TipoProcedencia == "3")
          producto.completado = true;
        else
          producto.completado = !(this.pasoProcedenciaCiudad || this.pasoProcedenciaCPOS);
      })
    }
    this.navigationService.procedenciasService.setProcedenciasValue(this.listadoProductos);
    if (!codebehind && (this.pasoProcedenciaCiudad || this.pasoProcedenciaCPOS))
    {
      this.nextProcedencia()
    }
  }

  seleccionarCiudad(ciudad, tipo, codebehind?:boolean)
  {
    this.ciudadSeleccionada = ciudad;
    this.pasoProcedenciaComunidad = true;
    //Comprobamos si tenemos hijos y el paso siguiente esta configurado
    // this.ciudadesFiltradas = this.ciudades.filter(ciudad => ciudad.PaisId == this.comunidadSeleccionada.PaisId && ciudad.CodComunidad == this.comunidadSeleccionada.CodComunidad);
    // if(this.ciudadesFiltradas.length >0 && this.configuration.datosTPVPathValue.TipoProcedencia > "2") 
    // {
    //   this.pasoProcedenciaCiudad = true;
    // }
    // else
    // {
    //   this.pasoProcedenciaCiudad = false;
    //   this.ciudadSeleccionada = null;
    // }

    if(this.aplicar == 1)
    {
      this.listadoProductos[this.indexProducto].procedencias.ciudad = this.ciudadSeleccionada;
      this.listadoProductos[this.indexProducto].completado = this.pasoProcedenciaCiudad;
    }
    else
    {
      this.listadoProductos.forEach(producto=> {
        producto.procedencias.ciudad = this.ciudadSeleccionada;
        producto.completado = this.pasoProcedenciaCiudad;
      })
    }

    if (!codebehind)
    {
      this.nextProcedencia()
    }
  }

  onBlurCPOS(evento)
  {
      //Metemos en listado productos
      if(this.aplicar == 1)
      {
        this.listadoProductos[this.indexProducto].procedencias.ciudad = this.valProcedenciaCPOS;
        this.listadoProductos[this.indexProducto].completado = true;
        this.nextProcedencia();
      }
      else
      {
        this.listadoProductos.forEach(producto=> {
          producto.procedencias.ciudad = this.valProcedenciaCPOS;
          producto.completado = true;
        })
      }
      this.navigationService.procedenciasService.setProcedenciasValue(this.listadoProductos);
      //this.navigationService.next();
  }

  isPaisSelected(pais, tipo): boolean
  {
    let respuesta = false;
    if(tipo==1)
    {
      if(this.paisSeleccionado && this.paisSeleccionado == pais) respuesta = true;
    }
    else
    {
      let paisComprobar = !this.paises ? null: this.paises.find(element => element.NombrePais == pais);
      if (this.paisSeleccionado && this.paisSeleccionado == paisComprobar) respuesta = true;
    }
    
    return respuesta;
  }

  isComunidadSelected(comunidad): boolean
  {
    let respuesta = false;
    if(this.comunidadSeleccionada && this.comunidadSeleccionada == comunidad) respuesta = true;
    return respuesta;
  }

  isCiudadSelected(ciudad): boolean
  {
    let respuesta = false;
    if(this.ciudadSeleccionada && this.ciudadSeleccionada == ciudad) respuesta = true;
    return respuesta;
  }

  changeAplicarA()
  {
    //<<Yaribel 20210303 Modificamos para que si es reservaAgrupada solo se puede saleccionar por entrada
    if(this.ventaService.carritoValue.reservaAgrupada == '1'){
      this.aplicar = 0; 
    } else{
      if(this.aplicar == 1)
      {this.aplicar = 0;}
      else
      {this.aplicar =1;}
    }
    //Yaribel 20210303>>
  }

  previoProcedencia()
  {
    if (this.indexProducto >0)
    {
      this.indexProducto--;
      //cargamos valores en su sitio
      this.paisSeleccionado = null;
      this.comunidadSeleccionada = null;
      this.ciudadSeleccionada = null;
      this.nombreEntradaActual = this.listadoProductos[this.indexProducto].nombre + ' ' + (this.indexProducto+1).toString() + '/' + this.listadoProductos.length.toString();
      if (this.listadoProductos[this.indexProducto].procedencias.pais != null)
      {
        this.seleccionarPais(this.listadoProductos[this.indexProducto].procedencias.pais, 1, true);
      }
      else
      {
        this.pasoProcedenciaComunidad = false;
        this.pasoProcedenciaCPOS = false
        this.pasoProcedenciaCiudad = false;
      }
      if (this.listadoProductos[this.indexProducto].procedencias.comunidad != null)
      {
        this.seleccionarComunidad(this.listadoProductos[this.indexProducto].procedencias.comunidad, 1, true);
      }
      else
      {
        this.pasoProcedenciaCPOS = false
        this.pasoProcedenciaCiudad = false;
      }
      if (this.listadoProductos[this.indexProducto].procedencias.ciudad != null)
      {
        if (this.configuration.datosTPVPathValue.TipoProcedencia == "5")
        {
          //metemos el valor en CPOS
          this.valProcedenciaCPOS = this.listadoProductos[this.indexProducto].procedencias.ciudad
        }
        else
        {
          this.seleccionarCiudad(this.listadoProductos[this.indexProducto].procedencias.ciudad, 1, true);
        }
      }

    }
  }

  nextProcedencia()
  {
    if (this.indexProducto < this.listadoProductos.length -1)
    {
      this.indexProducto++;
      //cargamos valores en su sitio
      this.paisSeleccionado = null;
      this.comunidadSeleccionada = null;
      this.ciudadSeleccionada = null;
      this.nombreEntradaActual = this.listadoProductos[this.indexProducto].nombre + ' ' + (this.indexProducto +1).toString() + '/' + this.listadoProductos.length.toString();
      if (this.listadoProductos[this.indexProducto].procedencias.pais != null)
      {
        this.seleccionarPais(this.listadoProductos[this.indexProducto].procedencias.pais, 1);
      }
      else
      {
        this.pasoProcedenciaComunidad = false;
        this.pasoProcedenciaCPOS = false
        this.pasoProcedenciaCiudad = false;
      }
      if (this.listadoProductos[this.indexProducto].procedencias.comunidad != null)
      {
        this.seleccionarComunidad(this.listadoProductos[this.indexProducto].procedencias.comunidad, 1);
      }
      else
      {
        this.pasoProcedenciaCPOS = false
        this.pasoProcedenciaCiudad = false;
      }
      if (this.listadoProductos[this.indexProducto].procedencias.ciudad != null)
      {
        if (this.configuration.datosTPVPathValue.TipoProcedencia == "5")
        {
          //metemos el valor en CPOS
          this.valProcedenciaCPOS = this.listadoProductos[this.indexProducto].procedencias.ciudad
        }
        else
        {
          this.seleccionarCiudad(this.listadoProductos[this.indexProducto].procedencias.ciudad, 1);
        }
      }
      
    }
  }

  abrirBusqueda()
  {
    
    this.abrirBuscarPais = !this.abrirBuscarPais;
    if (this.abrirBuscarPais)
    setTimeout(()=>{ // this will make the execution after the above boolean has changed
      this.inputBusqueda.nativeElement.focus();
    },0);
  }

  paisesPrimeraFila()
  {
    let paisesFiltrados;
    if (this.paisSeleccionado && (this.abrirBuscarPais || this.paisSeleccionadoEnBusqueda))
    {paisesFiltrados= this.paises.filter(x => x != this.paisSeleccionado);}
    else
    {paisesFiltrados = this.paises;}
    return paisesFiltrados == undefined || paisesFiltrados.length == 0 ?[]:paisesFiltrados.slice(0, this.numeroPaisesFila - (this.paisSeleccionado && (this.abrirBuscarPais || this.paisSeleccionadoEnBusqueda) ?1:0));
  }

  paisesSegundaFila()
  {
    let paisesFiltrados;
    if (this.abrirBuscarPais)
    {paisesFiltrados= this.paises.filter(x => this.valBusquedaPais.trim() == "" || x.NombrePais.toLowerCase().includes(this.valBusquedaPais.toLocaleLowerCase()));}
    return paisesFiltrados == undefined || paisesFiltrados.length == 0 ?[]:paisesFiltrados.slice(0, this.numeroPaisesFila - (this.paisSeleccionado && this.abrirBuscarPais ?1:0));
  }
  permitirPorEntrada(){
    return this.ventaService.carritoValue.reservaAgrupada == '1';
  }
  obtenerPais(procendencias, entrada) {
    if (!procendencias) return null;
    const proc = procendencias.find(x => x.nombre === entrada.entrada.NombrePantalla);
    return proc ? proc.procedencias.pais : null;
  };
  obtenerComunidad(procendencias, entrada) {
    if (!procendencias) return null;
    const proc = procendencias.find(x => x.nombre === entrada.entrada.NombrePantalla);
    return proc ? proc.procedencias.comunidad : null;
  };
  obtenerCiudad(procendencias, entrada) {
    if (!procendencias) return null;
    const proc = procendencias.find(x => x.nombre === entrada.entrada.NombrePantalla);
    return proc ? proc.procedencias.ciudad : null;
  };

  
}
