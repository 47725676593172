import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { PagerService } from 'src/app/shared/services/pager.service';
import { EspectaculosService } from '../../../services/espectaculos.service';
import { StepperNavigationService, VentaService } from 'src/app/modules/venta';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ConfigurationService } from '../../../../../core/conf/configuration.service';

@Component({
  selector: 'ticketing-lista-espectaculos',
  templateUrl: './lista-espectaculos.component.html',
  styleUrls: ['./lista-espectaculos.component.scss']
})
export class ListaEspectaculosComponent implements OnInit {
  arr = Array;
  pager: any = {};
  pagedItems: any[];
  espectaculoSeleccionado = this.espectaculosService.espectaculoSeleccionado;
  espectaculos;
  numEspectaculos;
  totalPages;
  page = 1;
  cargando = true;
  grupoRecintos = [];
  filtrosForm: FormGroup;
  espectaculosFiltrados = [];

  divisaSimboloDecimal: string; 
  divisaSimbSeparacionMiles: string;  
  divisaSimbolo: string;
  divisaDecimales: number;
  divisaPosicion: string;
  divisaSimboloDerecha: string;

  constructor(
    private pagerService: PagerService,
    private espectaculosService: EspectaculosService,
    private stepperNavigationService: StepperNavigationService,
    private router: Router,
    private ventaService: VentaService,
    private configurationService: ConfigurationService,
    private formBuilder: FormBuilder
  ) {
    this.filtrosForm = this.formBuilder.group({
      grupoRecinto: '',
      busqueda: ''
    });
  }
  @Output() paginaEmitter: EventEmitter<number> = new EventEmitter();
  ngOnInit() {
    this.getShopsByPage(this.page);
    this.divisaSimbSeparacionMiles = this.configurationService.divisasValue[0].SimbSeparacionMiles;
    this.divisaSimboloDecimal = this.configurationService.divisasValue[0].SimboloDecimal;
    this.divisaSimbolo = this.configurationService.divisasValue[0].simbolo;
    this.divisaDecimales = Number(this.configurationService.divisasValue[0].NumeroDecimales)
    this.divisaPosicion = this.configurationService.divisasValue[0].PosicionDivisa;
    this.divisaSimboloDerecha = this.configurationService.divisasValue[0].SimboloDerecha;
  }
  setPage(page: number): void {
    this.pager = this.pagerService.getPager(
      this.espectaculosFiltrados.length,
      page,
      9
    );
    this.pagedItems = this.espectaculosFiltrados.slice(
      this.pager.startIndex,
      this.pager.endIndex + 1
    );

    this.pager.pages = this.pager.pages.filter(
      num => num <= this.pager.totalPages
    );

    while (this.pager.pages.length < 6 && this.pager.pages[0] > 1) {
      this.pager.pages.unshift(this.pager.pages[0] - 1);
    }
  }
  seleccionarEspectaculo(e) {
    /* const regex = /noNumeradas/gm;
    if (regex.test(e.chNombreFicheroSVG)) {
      this.espectaculosService.setEspectaculoSeleccionadoValue(e);
      this.espectaculoSeleccionado = e;
      this.router.navigate(['/auditorio/seleccion-sesiones']);
    } else {
      this.espectaculosService.setEspectaculoSeleccionadoValue(e);
      this.espectaculoSeleccionado = e;
      this.stepperNavigationService.nextActividad();
    } */
    this.ventaService.setFechaSeleccionadaValue(
      moment(
        moment(e.dtFecha, 'YYYYMMDD').format('YYYY/MM/DD') +
          ' ' +
          moment().format('hh:mm:ss'),
        'YYYY/MM/DD hh:mm:ss'
      )
    );
    this.espectaculosService.setEspectaculoSeleccionadoValue(e);
    this.espectaculoSeleccionado = e;
    this.stepperNavigationService.nextActividad();
  }
  checkStatus(status) {
    switch (status) {
      case 0:
        return 'vencida';
      case 1:
        return 'pendiente';
      case 2:
        return 'pagada';
    }
  }
  goToPage(page: number) {
    this.page = page;
    this.getShopsByPage(page);
  }
  getShopsByPage(page) {
    this.cargando = true;
    this.espectaculosService.getEspectaculos(page).subscribe((res: any) => {
      this.cargando = false;
      this.espectaculos = res.DatosResult.sesiones;
      this.espectaculosFiltrados = [...this.espectaculos];
      this.grupoRecintos = [
        ...new Set(
          res.DatosResult.sesiones.map(item => item.chNombreGrupoRecintos)
        )
      ];
      this.grupoRecintos.unshift('TODOS');
      this.numEspectaculos = res.DatosResult.datosOutput.total;
      this.totalPages = res.DatosResult.datosOutput.numPages;
      this.setPage(page);
    });
  }

  deleteBusqueda() {}

  deleteTeatro() {}

  cambiodeFiltro(event) {
    // 
    // 
    if (
      this.filtrosForm.value.grupoRecinto === 'TODOS' &&
      this.filtrosForm.value.busqueda === ''
    ) {
      this.espectaculosFiltrados = [...this.espectaculos];
    } else if (
      this.filtrosForm.value.grupoRecinto === 'TODOS' &&
      this.filtrosForm.value.busqueda !== ''
    ) {
      this.espectaculosFiltrados = this.espectaculos.filter(
        item =>
          item.chNombreEspectaculo
            .toLowerCase()
            .includes(this.filtrosForm.value.busqueda.toLowerCase()) ||
          item.chNombreGrupoRecintos
            .toLowerCase()
            .includes(this.filtrosForm.value.busqueda.toLowerCase())
      );
    } else {
      this.espectaculosFiltrados = this.espectaculos.filter(
        item =>
          item.chNombreGrupoRecintos
            .toLowerCase()
            .includes(this.filtrosForm.value.grupoRecinto.toLowerCase()) &&
          (item.chNombreEspectaculo
            .toLowerCase()
            .includes(this.filtrosForm.value.busqueda.toLowerCase()) ||
            item.chNombreGrupoRecintos
              .toLowerCase()
              .includes(this.filtrosForm.value.busqueda.toLowerCase()))
      );
    }
    this.setPage(1);
  }

  siguiente() {
    if (this.page < this.totalPages) {
      this.page++;
      this.getShopsByPage(this.page);
    }
  }
  anterior() {
    if (this.page > 1) {
      this.page--;
      this.getShopsByPage(this.page);
    }
  }
  irPagina(page) {
    this.page = page;
    this.getShopsByPage(this.page);
  }
  pasarPagina() {
    this.paginaEmitter.emit(this.page);
  }
}
