import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { ConfigurationService } from 'src/app/core';
import { EntradasService, VentaService } from 'src/app/modules/venta';
import { Entrada } from 'src/app/modules/venta/models/entrada.model';
import { FormControlService } from 'src/app/shared/services/form-control.service';
import { NumpadService } from 'src/app/shared/services/numpad.service';
import { TranslateService } from 'src/app/shared/services/translate.service';
import { AsociadoService } from '../../asociado.service';

@Component({
  selector: 'ticketing-cliente-asociado',
  templateUrl: './cliente-asociado.component.html',
  styleUrls: ['./cliente-asociado.component.scss'],
  host: {
    class: 'layout'
  }
})

export class ClienteAsociadoComponent implements OnInit, OnDestroy  {

  mensajeError = '';
  tipoCliente;
  entradasConsumidas;
  entradasDisponibles;
  entradaSeleccionada;
  listaEntradasSeleccionadas;

  divisaSimboloDecimal: string; 
  divisaSimbSeparacionMiles: string;
  divisaSimbolo: string;
  divisaDecimales: number;
  divisaPosicion: string;
  divisaSimboloDerecha: string;
  esGranDivisa: boolean;

  fecha: any;
  idioma;
  tipoClienteSub: Subscription;
  entradasConsumidasSub: Subscription;
  entradasDisponiblesSub: Subscription;
  listaEntradasSeleccionadasSub: Subscription;
  mensajeErrorSub: Subscription;
  fechaSeleccionadaSub: Subscription;
  datosInicioSub: Subscription;

  constructor(private asociadoService: AsociadoService,
              private formControlService: FormControlService,
              private numpadService: NumpadService,
              private configuration: ConfigurationService,
              private ventaService: VentaService,
              private entradaService: EntradasService, private router: Router, private translate: TranslateService) { 

  }

  ngOnInit() {
    this.divisaSimbSeparacionMiles = this.configuration.divisasValue[0].SimbSeparacionMiles;
    this.divisaSimboloDecimal = this.configuration.divisasValue[0].SimboloDecimal;
    this.divisaSimbolo = this.configuration.divisasValue[0].simbolo;
    this.divisaDecimales = Number(this.configuration.divisasValue[0].NumeroDecimales)
    this.divisaPosicion = this.configuration.divisasValue[0].PosicionDivisa;
    this.divisaSimboloDerecha = this.configuration.divisasValue[0].SimboloDerecha;

    this.tipoClienteSub = this.asociadoService.tipoCliente$.subscribe(tipo =>{
      this.tipoCliente = tipo;
     });

     this.entradasConsumidasSub = this.asociadoService.entradasConsumidas$.subscribe(entradasC =>{
      this.entradasConsumidas = entradasC;
     });

     this.entradasDisponiblesSub = this.asociadoService.entradasDisponibles$.subscribe(entradasD =>{
      this.entradasDisponibles = entradasD;
     });

     this.listaEntradasSeleccionadasSub = this.asociadoService.listaEntradasSeleccionadas$.subscribe(lista =>{
       this.listaEntradasSeleccionadas = lista;
     });

     this.mensajeErrorSub = this.asociadoService.mensajeError$.subscribe(mensaje =>{
      this.mensajeError = mensaje || '';
    });

     this.fechaSeleccionadaSub = this.ventaService.fechaSeleccionada.subscribe(fecha => {
             this.fecha = fecha;
             this.asociadoService.setFechaVenta(fecha);
     });
     this.datosInicioSub = this.configuration.datosInicio.subscribe(
      conf => (this.idioma = conf.Idioma)
    );
  }

  ngOnDestroy() {
    if(this.tipoClienteSub) {this.tipoClienteSub.unsubscribe();}
    if(this.entradasConsumidasSub){this.entradasConsumidasSub.unsubscribe();}
    if(this.entradasDisponiblesSub){this.entradasDisponiblesSub.unsubscribe();}
    if(this.listaEntradasSeleccionadasSub){this.listaEntradasSeleccionadasSub.unsubscribe();}
    if(this.mensajeErrorSub){this.mensajeErrorSub.unsubscribe();}
    if(this.fechaSeleccionadaSub){this.fechaSeleccionadaSub.unsubscribe();}
    if(this.datosInicioSub){this.datosInicioSub.unsubscribe();}   
  }


  mostrarTexto(){
    let texto = '';
    if(this.tipoCliente)
    {
      texto = this.tipoCliente + ' ';
    }
    if(this.mensajeError.length > 0) 
    {
      texto += this.mensajeError|| ''; 
    }
    return  texto;
  }

  selectEntrada(entrada) {
    this.entradaSeleccionada = entrada;
    const input = document.getElementById(`motivo-${this.entradaSeleccionada.ItemId}`);
    this.asociadoService.selected = input;
    this.asociadoService.entrada = this.entradaSeleccionada;
    this.formControlService.setCurrentFormGroup(null, input, input.getAttribute('category'));
    if ((<HTMLElement>event.target).getAttribute('category') === 'ASOCIADO') {
       this.numpadService.commaDisabled = true;
     } else {
       this.numpadService.commaDisabled = false;
     }
  }
  getPrecioTotal() {
    return this.asociadoService.getPrecioTotal();
  }

  getCantidadTotal() {
    return this.asociadoService.getCantidadTotal();
  }

  avanzar() {

    let carrito = this.ventaService.carritoValue;
    if(this.listaEntradasSeleccionadas){
    this.listaEntradasSeleccionadas.forEach(element => {
      carrito.entradas = carrito.entradas.filter(x=> x.entrada.idTarifa !== element.idTarifa);
      if(this.ventaService.carritoValue.entradas.find(x=> x.entrada.idTarifa == element.idTarifa))
      {
        this.eliminarEntradasVerificandoAforo(this.ventaService.carritoValue.find(x=> x.entrada.idTarifa == element.idTarifa));
      }

      // if(this.ventaService.carritoValue.entradas.find(x=> x.entrada.idTarifa == element.idTarifa))
      // {
      //   this.eliminarEntradaCarrito(this.ventaService.carritoValue.entradas.find(x=> x.entrada.idTarifa == element.idTarifa));
      //   //this.ventaService.removeEntrada(this.ventaService.carritoValue.entradas.find(x=> x.entrada.idTarifa  == element.idTarifa))
      // }
    });
    // this.ventaService.setCarrito(carrito);
    this.listaEntradasSeleccionadas.forEach(element => {
      this.agregarEntrada(element);
    });
  }
    this.router.navigate(['']);
  }

  eliminarEntrada(entrada) {
    const entradas = this.listaEntradasSeleccionadas.filter(m=> m.ItemId !== entrada.ItemId)
    this.asociadoService.setListaEntradasSeleccionadas(entradas);
    // const oldVals = {
    //   entradaSeleccionada: this.asociadoService.entrada,
    //   selected: this.asociadoService.selected
    // };
    // this.entradaSeleccionada = entrada;
    //  const input = document.getElementById(`motivo-${this.entradaSeleccionada.ItemId}`);
    //  this.asociadoService.selected = input;
    //  this.asociadoService.entrada = this.entradaSeleccionada;
    // this.formControlService.setCurrentFormGroup(null, input, input.getAttribute('category'));
    // this.formControlService.setToBlank();

    // if (oldVals.entradaSeleccionada) {
    //   this.entradaSeleccionada = oldVals.entradaSeleccionada;
    // }
    // if (oldVals.selected) {
    //   this.asociadoService.selected = oldVals.selected;
    // }

    // this.asociadoService.entrada = this.entradaSeleccionada;
    //  this.formControlService.setCurrentFormGroup(
    //    null,
    //    this.asociadoService.selected,
    //    this.asociadoService.selected.getAttribute('category')
    //  );
    // // this.numpadService.entrada = entradaOriginal;
  }

  focusValue(event: any, entrada) {
     this.entradaSeleccionada = entrada;
     event.srcElement.value = '';
    this.asociadoService.selected = event.srcElement;
    this.asociadoService.entrada = entrada;
     this.formControlService.setCurrentFormGroup(
       null,
       event.srcElement,
       event.srcElement.getAttribute('category')
     );
  }

  unfocusValue(event: any) {
     if (event.srcElement.value === '') {
       this.asociadoService.setNumpadOrder('reset');
      return;
     }
  }

  inputDeTeclado(event: any, entrada: Entrada) {
     event.preventDefault();
     const charCode = event.keyCode;
    this.asociadoService.selected = event.srcElement;
    this.asociadoService.entrada = entrada;
     this.formControlService.setCurrentFormGroup(
       null,
       event.srcElement,
       event.srcElement.getAttribute('category')
     );

    if (this.isDeleteButtonPressed(charCode)) {
       this.formControlService.deleteChar();
      return;
    }

    if (this.isEnterButton(charCode)) {
       this.formControlService.unfocus();
      event.srcElement.blur();
      return;
    }

    if (this.isNumberKey(charCode)) {
      this.formControlService.inputChar(event.key);
      return;
    }
  }

  isNumberKey(charCode: number) {
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  isDeleteButtonPressed(charCode: number) {
    return charCode === 8 ? true : false;
  }

  isEnterButton(charCode: number) {
    return charCode === 13 ? true : false;
  }

  agregarEntrada(entrada: any): void {
    if (entrada.noEditable || this.ventaService.carritoValue.reservaAgrupada == '1') {
      return;
    }
    if (this.ventaService.carritoValue.horarios.length > 0) {
      const recintosAnadir = [];
      const indicesEliminar = [];
      if (entrada.TipoProducto === '1') {
        entrada.recintos.forEach(r => recintosAnadir.push(r.Recinto));
        this.ventaService.carritoValue.horarios.forEach((item, index) => {
        if (recintosAnadir.includes(item.sesion.RecintoId.toString())) {
          
          if (item.sesion.RecintosSesionId) {
            this.ventaService
              .eliminarAforoSesion(item.sesion.RecintosSesionId)
              .subscribe(data => {
              });
              indicesEliminar.push(index);
          } else {
            indicesEliminar.push(index);
          }
        }
      });
      } else if (entrada.TipoProducto === '2') {
        entrada.PromocionEntradas.forEach(item => {
          entrada.recintos.forEach(r => recintosAnadir.push(r.Recinto));
          this.ventaService.carritoValue.horarios.forEach((item2, index) => {
            if (recintosAnadir.includes(item2.sesion.RecintoId.toString()) &&
              item2.recinto.entradas.includes(item.TipoEntradaId)) {
              
              // item.cantidad = item.cantidad + 1;
              if (item2.sesion.RecintosSesionId) {
                this.ventaService
                .eliminarAforoSesion(item2.sesion.RecintosSesionId)
                .subscribe(data => {
                });
                indicesEliminar.push(index);
              } else {
                indicesEliminar.push(index);
              }
            }
          });
        });
      }
      if (indicesEliminar.length > 0) {
        indicesEliminar.forEach(indice => {
          
          if (indice > 0) {
            indice --;
          }
            this.ventaService.carritoValue.horarios.splice(indice, 1);
        });
      }
    }
    const objCaracteristicas = {
      Tipo: '', Rango: '', Nivel: '', Profesor: ''
    };
    if (!entrada.CaracteristicasEntrada) {
      entrada.CaracteristicasEntrada = [];
    }

    this.entradaService.setEntradaSeleccionada(entrada);
    let motivoSeleccionable: any;              

    if (entrada.GrupoMotivoId && Number.parseInt(entrada.GrupoMotivoId , 10) > 0) {
      //this.modalService.open('selector-motivos');
      return;
    }else{
      ;
      for(let i=0;i < entrada.cantidad;i++){
        this.ventaService.sendEntrada(entrada);
      }      
    }
    // this.visor.mostrarDisplayLineaAlbaran(
    //   this.getCantidadPorEntrada(entrada),
    //   entrada.PrecioEnDivisaReferencia
    // );
  }


  eliminarEntradaCarrito(entrada: any): void {
    if (entrada.noEditable ||this.ventaService.carritoValue.reservaAgrupada == '1') {
      return;
    }
    const cantidadEntradas = this.ventaService.getCantidadPorEntrada(entrada);
    if (cantidadEntradas > 0) {
      this.entradaService.setEntradaSeleccionada(entrada);
      ;
      if (entrada.CaracteristicasEntrada){
        entrada.CaracteristicasEntrada.splice(entrada.CaracteristicasEntrada.length - 1);
      }
                       
      if (entrada.GrupoMotivoId && Number.parseInt(entrada.GrupoMotivoId , 10) > 0) {               
          //this.modalService.open('selector-motivos');
          return;
      } else {
        this.eliminarEntradasVerificandoAforo(entrada);
      }

    }
  }

  eliminarEntradasVerificandoAforo(entrada: any){
  if (this.ventaService.carritoValue.horarios.length > 0) {
    const recintosEliminar = [];
    const indicesEliminar = [];
    entrada.recintos.forEach(r => recintosEliminar.push(r.Recinto));
    this.ventaService.carritoValue.horarios.forEach((item, index) => {
      
      if (recintosEliminar.includes(item.recinto.Recinto)) {
        if (item.cantidadxentrada && item.cantidadxentrada.length > 0) {
          if (!entrada.Caracteristicas || entrada.Caracteristicas.NumPeriodos == '') {
            if (item.cantidad - 1 > 0) {
              item.cantidad = item.cantidad - 1;
              item.resumen = `${item.recinto.NombreRecinto} - ${moment(
                this.fecha,
                'YYYY/MM/DD'
              )
                .locale(this.idioma)
                .format('L')} ${item.sesion.HoraInicio} (${item.cantidad})`;
                this.ventaService
                .reservaAforoProductos(this.mapeaListaentradas2ListaProductos(item.cantidadxentrada), 
                item.recinto.Recinto, this.fechaYYYYMMDD2slash(item.sesion.Fecha, item.sesion.HoraInicio) )
                .subscribe();
            } else {
              this.ventaService
              .eliminarAforoSesion(item.sesion.RecintosSesionId)
              .subscribe(data => {});
              indicesEliminar.push(index);
            }
          } else {
            if (item.cantidadxentrada.length > 0) {
              this.ventaService
              .reservaAforoProductos(this.mapeaListaentradas2ListaProductos(item.cantidadxentrada, entrada.TipoEntradaId), 
              item.recinto.Recinto, this.fechaYYYYMMDD2slash(item.sesion.Fecha, item.sesion.HoraInicio) )
              .subscribe();
            }
          }
        } else {
          if (item.cantidad - 1 > 0) {
            item.cantidad = item.cantidad - 1;
            item.resumen = `${item.recinto.NombreRecinto} - ${moment(
              this.fecha,
              'YYYY/MM/DD'
            )
              .locale(this.idioma)
              .format('L')} ${item.sesion.HoraInicio} (${item.cantidad})`;
              // <<Yaribel 20210112 cambiamos peticion para que llame a reservaAforoProductos
              this.ventaService
              .reservaAforoProductos(this.mapeaListaentradas2ListaProductos(item.getTotalCantidadXEntradasSinPeriodos), 
              item.recinto.Recinto, this.fechaYYYYMMDD2slash(item.sesion.Fecha, item.sesion.HoraInicio))
              .subscribe();
          } else {
            this.ventaService
            .eliminarAforoSesion(item.sesion.RecintosSesionId)
            .subscribe(data => {});
            indicesEliminar.push(index);
          }
        }
      }
    });
    if (indicesEliminar.length > 0) {
      indicesEliminar.forEach(indice => {
        
        if (indice > 0) {
          indice --;
        }
          this.ventaService.carritoValue.horarios.splice(indice, 1);
      });
    }
  }
  this.ventaService.removeEntrada(entrada);
}

mapeaListaentradas2ListaProductos( listaentradas, filtroProducto?) :string[]
{
  let respuesta:string[] = [];
  listaentradas.forEach(element => {
    let idProducto = "";
    if (element.entrada.TipoProducto == "2")
      {
        if (!filtroProducto ||  filtroProducto == element.entrada.TipoPromocionId)
        idProducto = "2," + element.entrada.TipoPromocionId + "," + element.cantidad.toString();
      }
      else if (element.entrada.TipoProducto == "3")
      {
        if (!filtroProducto ||  filtroProducto == element.entrada.TipoAbonadoId)
        idProducto = "3," + element.entrada.TipoAbonadoId + "," + element.cantidad.toString();
      }
      else
      {
        if (!filtroProducto ||  filtroProducto == element.entrada.TipoEntradaId)
        idProducto = "1," + element.entrada.TipoEntradaId + "," + element.cantidad.toString();
      }
    respuesta.push(idProducto);
  });
  return respuesta;
}

fechaYYYYMMDD2slash(fecha, hora)
{
  let respuesta = "";
  if (fecha && fecha.length == 8)
    {
      respuesta = fecha.substring(0, 4) + "/" + fecha.substring(4,6) + "/" + fecha.substring(6);
    } 
  if (hora && hora.length == 5)
    {
      respuesta = respuesta + " " + hora + ":00";
    }
    else if (hora && hora.length == 8)
    {
      respuesta = respuesta + " " + hora;
    }
    else 
    {
      respuesta = respuesta + " " + "23:59:00";
    }
    return respuesta;
}

colorTexto(){
    if(this.tipoCliente)
    {
      if(this.tipoCliente == this.translate.instant('CLIENTE_MAPFRE') || this.tipoCliente == this.translate.instant('CLIENTE_PLATINO'))
      return 'verde';
      if(this.tipoCliente == this.translate.instant('NO_CLIENTE_ASOCIADO') || this.tipoCliente == this.translate.instant('SERVICIO_NO_DISPONIBLE'))
      return 'naranja';
    }
    if(this.mensajeError && this.mensajeError.length > 0) 
    {
      return 'rojo';
    }

    return  'rojo';
 

  }
}
