import { Component, OnInit, OnDestroy, ElementRef, ViewChild, AfterContentChecked } from '@angular/core';
import { FormControl, FormControlDirective, FormControlName, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormControlService } from 'src/app/shared/services/form-control.service';
import { takeWhile, first } from 'rxjs/operators';
import { VisorService } from 'src/app/shared/services/visor.service';
import { PermisosService } from 'src/app/core/security/permisos.service';
import { UserManagerService } from 'src/app/core/auth/user-manager.service';
import { UsuarioElevadoService } from 'src/app/core/security/usuario-elevado.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { InfoToast } from 'src/app/core/toast/info.toast';
import { ModalService } from 'src/app/shared/services/modal.service';
import { Router } from '@angular/router';
import { SidebarNavigationService } from 'src/app/shared/services/sidebar-navigation.service';
import { ConfigurationService } from 'src/app/core';
import { ConditionalExpr } from '@angular/compiler';
import { StepperNavigationService, Carrito } from 'src/app/modules/venta';
import { AlbaranService } from '../../services/albaran.service';
import { DevolucionService } from '../../services/devolucion.service';
import { AlbaranFOP } from '../../models/albaran.model';
import { TranslateService } from 'src/app/shared/services/translate.service';
import { MyCurrencyPipe } from 'src/app/shared/pipes/myCurrency.pipe';

@Component({
  selector: 'ticketing-selector-pago-devolucion',
  templateUrl: './selector-pago-devolucion.component.html',
  styleUrls: ['./selector-pago-devolucion.component.scss']
})
export class SelectorPagoDevolucionComponent implements OnInit, AfterContentChecked, OnDestroy {
  @ViewChild('recibidoInput') private elementRef: ElementRef;
  pagosForm: FormGroup;
  optionsForm: FormGroup;
  promocionalesForm: FormGroup;
  codigoClienteForm: FormGroup; //Yaribel 20210722 Añadimos CodigoCliente
  cambio: number;
  diferencia: number;
  pagoTotal = 0;
  verTransferencia = false;
  verTarjeta = false;
  verMetalico = false;
  valorEfectivo = 0;
  conf: any;
  camposObligatorios = '0';
  // Yaribel 11112020  Añadimos variables para controlar la nueva forma de pago Tarjeta monedero
  verMonedero = false;
  verAplazado = false;
  verPrepago = false;
  codigoTarjetaMonedero: string = '';
  verTarjetaMonederoCodigo = false;
  placeholderValue: string;
  divisaSimboloDecimal: string; 
  divisaSimbSeparacionMiles: string;  
  divisaSimbolo: string;
  divisaDecimales: number;
  divisaPosicion: string;
  esGranDivisa: boolean;
  divisaSimboloDerecha: string;

  private alive = true;
  precioPrepago: any;

  constructor(
    private formBuilder: FormBuilder,
    private stepper: StepperNavigationService,
    private formControlService: FormControlService,
    private permisosService: PermisosService,
    private user: UserManagerService,
    private usuarioElevadoService: UsuarioElevadoService,
    private toastService: ToastrService,
    private visor: VisorService,
    private router: Router,
    private sidebarNavigation: SidebarNavigationService,
    private configuration: ConfigurationService,
    private albaranService: AlbaranService,
    private devolucionService: DevolucionService,
    private translate: TranslateService,
    private myCurrencyPipe: MyCurrencyPipe
  ) {
  }

  ngOnInit() {
    this.conf = this.configuration.appConfig.value;
    this.stepper.bloquearNavegacionPorPago();
    this.sidebarNavigation.blockSecciones(this.router.url);
    this.pagosForm = this.formBuilder.group({
      prepago: [
        '',
        Validators.compose([
          Validators.min(0),
          Validators.max(0),
          Validators.pattern('^[0-9]+(.[0-9]{0,2})?')
        ])
      ],
      aplazado: [
        '',
        Validators.compose([
          Validators.min(0),
          Validators.pattern('^[0-9]+(.[0-9]{0,2})?')
        ])
      ],
      transferencia: [
        '',
        Validators.compose([
          Validators.min(0),
          Validators.pattern('^[0-9]+(.[0-9]{0,2})?')
        ])
      ],
      pinpad: [
        '',
        Validators.compose([
          Validators.min(0),
          Validators.pattern('^[0-9]+(.[0-9]{0,2})?')
        ])
      ],
      datafono: [
        '',
        Validators.compose([
          Validators.min(0),
          Validators.pattern('^[0-9]+(.[0-9]{0,2})?')
        ])
      ],
      metalico: [
        '',
        Validators.compose([
          Validators.min(0),
          Validators.pattern('^[0-9]+(.[0-9]{0,2})?')
        ])
      ],
      total: [
        this.devolucionService.carritoDevolucion.precioTotal,
        Validators.compose([
          Validators.min(0),
          Validators.pattern('^[0-9]+(.[0-9]{0,2})?')
        ])
      ], //  Yaribel 11112020  Añadimos a pagoForm el control monedero
      monedero: [
        '',
        Validators.compose([
          Validators.min(0),
          Validators.pattern('^[0-9]+(.[0-9]{0,2})?')
        ])
      ],
      saldoMonedero: [
        '', Validators.compose([
          Validators.min(0),
          Validators.pattern('^[0-9]+(.[0-9]{0,2})?')
        ])
      ],
      codigoMonedero: [
        '', Validators.compose([
          Validators.pattern('^[A-Za-z0-9]*$')
        ])
      ],
      //Yaribel 11112020  fin codigo añadido
      efectivo: [
        '',
        Validators.compose([
          Validators.min(0),
          Validators.pattern('^[0-9]+(.[0-9]{0,2})?')
        ])
      ]
    });
    // 
    this.devolucionService.carritoDevolucion$.pipe(takeWhile(() => this.alive)).subscribe((carrito) => {
      let precioTotal = 0;
      //
      if (carrito) {
        carrito.entradas.forEach((entrds) => {
          //
          if (!entrds.entrada.noEditable) {
            precioTotal =
              precioTotal +
              (Number.parseFloat(
                  entrds.entrada.PrecioEnDivisaReferencia.replace(/,/, '.')
                ) +
                Number.parseFloat(entrds.entrada.Comision.replace(/,/, '.'))) *
              entrds.cantidad;
          }
        });
        // this.pagosForm.controls['prepago'].patchValue(carrito.precioPrepago);
        //this.precioPrepago = carrito.precioPrepago;
      }
      //
      //
      this.cambio = this.pagoTotal - precioTotal;
      this.cambio = Math.round(this.cambio * 100) / 100;
    });
    this.formControlService.setCurrentFormGroup(
      this.pagosForm,
      document.getElementById('RecibidoInput'),
      document.getElementById('RecibidoInput') ? document.getElementById('RecibidoInput').getAttribute('category') : ''
    );
    this.devolucionService.resetPagoDesglosado();
    this.devolucionService.setPagoTotal(0);
    this.devolucionService.setCambio(0 - this.devolucionService.carritoDevolucion.precioTotal);
    this.onChanges();
    this.InicializarFormaPagoOrigen(this.devolucionService.carritoDevolucion.precioTotal, JSON.parse(JSON.stringify(this.albaranService.albaranValue.AlbaranFOPs)));
    this.visor.mostrarDisplayTotalAlbaran(this.devolucionService.carritoDevolucion.precioTotal);
    this.divisaSimbSeparacionMiles = this.configuration.divisasValue[0].SimbSeparacionMiles;
    this.divisaSimboloDecimal = this.configuration.divisasValue[0].SimboloDecimal;
    this.divisaSimbolo = this.configuration.divisasValue[0].simbolo;
    this.divisaDecimales = Number(this.configuration.divisasValue[0].NumeroDecimales)
    this.divisaPosicion = this.configuration.divisasValue[0].PosicionDivisa;
    this.divisaSimboloDerecha = this.configuration.divisasValue[0].SimboloDerecha;
    this.placeholderValue = this.myCurrencyPipe.transform(0, this.divisaSimboloDecimal, this.divisaDecimales, this.divisaPosicion, this.divisaSimbolo, this.divisaSimboloDerecha, this.divisaSimbSeparacionMiles)
    this.pagosForm.enable();
  }

  ngOnDestroy() {
    this.alive = false;
    this.devolucionService.setCarritoDevolucion(new Carrito());
    this.albaranService.anulacionTotal.next(false);
    this.usuarioElevadoService.reducir();
  }

  ngAfterContentChecked() {
    const importeC: number = this.albaranService.albaranValue.AlbaranFOPs.find((x) => x.FOP == 'CC') ? Number.parseFloat(
      this.conversor(this.albaranService.albaranValue.AlbaranFOPs.find((x) => x.FOP == 'CC').ImportePendiente).toFixed(2)) : 0;
    const importeT: number = this.albaranService.albaranValue.AlbaranFOPs.find((x) => x.FOP == 'TR') ? Number.parseFloat(this.conversor(
      this.albaranService.albaranValue.AlbaranFOPs.find((x) => x.FOP == 'TR').ImportePendiente).toFixed(2)) : 0;
    const importeM: number = this.albaranService.albaranValue.AlbaranFOPs.find((x) => x.FOP == 'CA') ? Number.parseFloat(
      this.conversor(this.albaranService.albaranValue.AlbaranFOPs.find((x) => x.FOP == 'CA').ImportePendiente).toFixed(2)) : 0;
    const importeTM: number = this.albaranService.albaranValue.AlbaranFOPs.find(
      (x) => x.FOP == 'TM'
    )
      ? Number.parseFloat(
        this.conversor(
          this.albaranService.albaranValue.AlbaranFOPs.find(
            (x) => x.FOP == 'TM'
          ).ImportePendiente
        ).toFixed(2)
      )
      : 0;
    const importeDP: number = this.albaranService.albaranValue.AlbaranFOPs.find(
      (x) => x.FOP == 'DP'
    )
      ? Number.parseFloat(
        this.conversor(
          this.albaranService.albaranValue.AlbaranFOPs.find(
            (x) => x.FOP == 'DP'
          ).ImportePendiente
        ).toFixed(2)
      )
      : 0;
    const importePR: number = this.albaranService.albaranValue.AlbaranFOPs.find(
      (x) => x.FOP == 'PR'
    )
      ? Number.parseFloat(
        this.conversor(
          this.albaranService.albaranValue.AlbaranFOPs.find(
            (x) => x.FOP == 'PR'
          ).ImportePendiente
        ).toFixed(2)
      )
      : 0;

    this.pagosForm.controls['transferencia'].setValidators([
      Validators.max(importeT),
      Validators.min(0),
      Validators.pattern('^[0-9]+(.[0-9]{0,2})?')
    ]);
    this.pagosForm.controls['pinpad'].setValidators([
      Validators.max(importeC),
      Validators.min(0),
      Validators.pattern('^[0-9]+(.[0-9]{0,2})?')
    ]);
    this.pagosForm.controls['datafono'].setValidators([
      Validators.max(importeC),
      Validators.min(0),
      Validators.pattern('^[0-9]+(.[0-9]{0,2})?')
    ]);
    this.pagosForm.controls['metalico'].setValidators([
      Validators.max(importeM),
      Validators.min(0),
      Validators.pattern('^[0-9]+(.[0-9]{0,2})?')
    ]);

    this.pagosForm.controls['monedero'].setValidators([
      Validators.max(importeTM),
      Validators.min(0),
      Validators.pattern('^[0-9]+(.[0-9]{0,2})?')
    ]);
    this.pagosForm.controls['aplazado'].setValidators([
      Validators.max(importeDP),
      Validators.min(0),
      Validators.pattern('^[0-9]+(.[0-9]{0,2})?')
    ]);
    this.pagosForm.controls['prepago'].setValidators([
      Validators.max(importePR),
      Validators.min(0),
      Validators.pattern('^[0-9]+(.[0-9]{0,2})?')
    ]);
  }

  onChanges() {
    this.pagosForm.valueChanges
      .pipe(takeWhile(() => this.alive))
      .subscribe((val) => {
        const valores = {
          prepago: this.conversor(val.prepago) || 0,
          transferencia: this.conversor(val.transferencia) || 0,
          pinpad: this.conversor(val.pinpad) || 0,
          datafono: this.conversor(val.datafono) || 0,
          efectivo: (val.metalico ? this.conversor(val.metalico) : 0) || 0,
          monedero: this.conversor(val.monedero) || 0, // Yaribel 11112020  añadimos forma de pago TM
          aplazado: this.conversor(val.aplazado) || 0
        };
        this.devolucionService.setPagoDesglosado(valores);
        // 
        this.pagoTotal = Object.keys(valores).reduce((prev, key) => {
          prev += valores[key];
          return prev;
        }, 0);
        this.devolucionService.setPagoTotal(this.pagoTotal + (val.efectivo ? this.conversor(val.efectivo) : 0));
        //
        let precioTotal = 0;
        this.devolucionService.carritoDevolucion.entradas.forEach((entrds) => {
          if (!entrds.entrada.noEditable) {
            precioTotal =
              precioTotal +
              (Number.parseFloat(
                  entrds.entrada.PrecioEnDivisaReferencia.replace(/,/, '.')
                ) +
                Number.parseFloat(entrds.entrada.Comision.replace(/,/, '.'))) *
              entrds.cantidad;
          }
        });
        // 
        const importeC: number = this.albaranService.albaranValue.AlbaranFOPs.find(x => x.FOP == 'CC') ?
          Number.parseFloat(this.conversor(this.albaranService.albaranValue.AlbaranFOPs.find(x => x.FOP == 'CC').ImportePendiente).toFixed(2)) : 0;
        const importeT: number = this.albaranService.albaranValue.AlbaranFOPs.find(x => x.FOP == 'TR') ?
          Number.parseFloat(this.conversor(this.albaranService.albaranValue.AlbaranFOPs.find(x => x.FOP == 'TR').ImportePendiente).toFixed(2)) : 0;
        const importeM: number = this.albaranService.albaranValue.AlbaranFOPs.find(x => x.FOP == 'CA') ?
          Number.parseFloat(this.conversor(this.albaranService.albaranValue.AlbaranFOPs.find(x => x.FOP == 'CA').ImportePendiente).toFixed(2)) : 0;
        // Yaribel 11112020 Añadimos variables para la nueva forma de pago TM
        const importeTM: number = this.albaranService.albaranValue.AlbaranFOPs.find(x => x.FOP == 'TM') ?
          Number.parseFloat(this.conversor(this.albaranService.albaranValue.AlbaranFOPs.find(x => x.FOP == 'TM').ImportePendiente).toFixed(2)) : 0;
        const importeDP: number = this.albaranService.albaranValue.AlbaranFOPs.find(x => x.FOP == 'DP') ?
          Number.parseFloat(this.conversor(this.albaranService.albaranValue.AlbaranFOPs.find(x => x.FOP == 'DP').ImportePendiente).toFixed(2)) : 0;
        // Yaribel 20201218 Añadimos variables para la nueva forma de pago PR
        const importePR: number = this.albaranService.albaranValue.AlbaranFOPs.find(x => x.FOP == 'PR') ?
          Number.parseFloat(this.conversor(this.albaranService.albaranValue.AlbaranFOPs.find(x => x.FOP == 'PR').ImportePendiente).toFixed(2)) : 0;

        this.verMonedero = importeTM > 0;
        this.verTransferencia = importeT > 0;
        this.verTarjeta = importeC > 0;
        this.verMetalico = importeM > 0;
        this.verAplazado = importeDP > 0;
        this.verPrepago = importePR > 0;
        this.cambio = (val.efectivo ? this.conversor(val.efectivo) : 0) + (val.metalico ? this.conversor(val.metalico) : 0) || 0;
        this.cambio = Number.parseFloat(this.cambio.toFixed(2));
        this.diferencia = this.pagoTotal;
        this.devolucionService.setDiferencia(this.diferencia);
        this.devolucionService.setCambio(this.cambio);
        this.devolucionService.setFormularioCorrecto(this.pagosForm.status == 'VALID');
      });
  }

  focusValue(event: any) {

    // 
    this.formControlService.setCurrentFormGroup(
      this.pagosForm,
      event.srcElement,
      event.srcElement.getAttribute('category')
    );
    const inputSeleccionado = this.formControlService.currentFormGroupValue.elemento.getAttribute(
      'formcontrolname'
    );

    if (inputSeleccionado === 'pinpad') {
      if (this.albaranService.albaranValue.PagoCCInfo.TieneLectorElectronico == 'True') {
        if (this.pagosForm.controls['datafono'].value) {
          this.pagosForm.controls['datafono'].patchValue('');
        }
      } else {
        this.toastService.info('', this.translate.instant('SOLO_DEVOLUCION_DATAFONO'), {
          toastComponent: InfoToast,
          timeOut: 5000
        });
      }

    } else if (inputSeleccionado === 'datafono') {
      if (this.pagosForm.controls['pinpad'].value) {
        this.pagosForm.controls['pinpad'].patchValue('');
      }
    }

    const valorInputSeleccionado = this.formControlService.currentFormGroupValue
      .form.controls[inputSeleccionado].value;

    this.formControlService.setCurrentFormGroup(
      this.pagosForm,
      event.srcElement,
      event.srcElement.getAttribute('category')
    );

    // this.pagosForm.controls['prepago'].patchValue(this.venta.carritoValue.precioPrepago);
    // this.formControlService.currentFormGroupValue.form.controls[inputSeleccionado].patchValue(valorInputSeleccionado);
  }

  // focusValue(event: any) {
  //   this.formControlService.setCurrentFormGroup(
  //     this.pagosForm,
  //     event.srcElement,
  //     event.srcElement.getAttribute('category')
  //   );
  //   this.formControlService.setCurrentFormGroup(
  //     this.pagosForm,
  //     document.getElementById('RecibidoInput'),
  //     document.getElementById('RecibidoInput').getAttribute('category')
  //   );
  //   const inputSeleccionado = this.formControlService.currentFormGroupValue.elemento.getAttribute(
  //     'formcontrolname'
  //   );
  //   const valorInputSeleccionado = this.formControlService.currentFormGroupValue
  //     .form.controls[inputSeleccionado].value;

  //   this.formControlService.currentFormGroupValue.form.controls[
  //     inputSeleccionado
  //   ].patchValue('');
  //   this.formControlService.setCurrentFormGroup(
  //     this.pagosForm,
  //     event.srcElement,
  //     event.srcElement.getAttribute('category')
  //   );
  //   // this.pagosForm.controls['prepago'].patchValue(this.venta.carritoValue.precioPrepago);
  //   // this.formControlService.currentFormGroupValue.form.controls[inputSeleccionado].patchValue(valorInputSeleccionado);
  // }

  // focusMetalicoValue(event: any) {
  //   if (
  //     this.pagosForm.controls['metalico'].value !==
  //     this.devolucionService.carritoDevolucion.precioTotal
  //   ) {
  //     this.formControlService.setCurrentFormGroup(
  //       this.pagosForm,
  //       event.srcElement,
  //       event.srcElement.getAttribute('category')
  //     );
  //     const pinpadP: number = this.pagosForm.controls['pinpad'].value
  //       ? this.pagosForm.controls['pinpad'].value.replace(',', '.')
  //       : 0;
  //     const datafonoP: number = this.pagosForm.controls['datafono'].value
  //       ? this.pagosForm.controls['datafono'].value.replace(',', '.')
  //       : 0;
  //     const transferenciaP: number = this.pagosForm.controls['transferencia']
  //       .value
  //       ? this.pagosForm.controls['transferencia'].value.replace(',', '.')
  //       : 0;
  //     const tarjetaMonederoP: number = this.pagosForm.controls['monedero'].value
  //       ? this.pagosForm.controls['monedero'].value.replace(',', '.')
  //       : 0;
  //     const aplazadoP: number = this.pagosForm.controls['aplazado'].value
  //       ? this.pagosForm.controls['aplazado'].value.replace(',', '.')
  //       : 0;
  //     const prepagoP: number = this.pagosForm.controls['prepago'].value
  //       ? this.pagosForm.controls['prepago'].value.replace(',', '.')
  //       : 0;

  //     let inputResultante: number =
  //       this.devolucionService.carritoDevolucion.precioTotal -
  //       this.devolucionService.carritoDevolucion.precioPrepago -
  //       datafonoP -
  //       pinpadP -
  //       transferenciaP -
  //       tarjetaMonederoP -
  //       aplazadoP -
  //       prepagoP;
  //     const inputSeleccionado = this.formControlService.currentFormGroupValue.elemento.getAttribute(
  //       'formcontrolname'
  //     );
  //     const importeT: number = this.albaranService.albaranValue.AlbaranFOPs.find(
  //       (x) => x.FOP == 'CA'
  //     )
  //       ? Number.parseFloat(
  //           this.conversor(
  //             this.albaranService.albaranValue.AlbaranFOPs.find(
  //               (x) => x.FOP == 'CA'
  //             ).ImportePendiente
  //           ).toFixed(2)
  //         )
  //       : 0;
  //     if (importeT == 0) {
  //       inputResultante = 0;
  //     } else {
  //       if (inputResultante > importeT) {
  //         inputResultante = importeT;
  //       }
  //       if (inputResultante < 0) {
  //         inputResultante = 0;
  //       }
  //     }
  //     this.formControlService.currentFormGroupValue.form.controls[
  //       inputSeleccionado
  //     ].patchValue(inputResultante.toFixed(2).replace('.', ','));
  //     this.formControlService.currentFormGroupValue.form.controls[
  //       inputSeleccionado
  //     ].setValidators([Validators.max(importeT), Validators.min(0)]);
  //     this.pagosForm.controls[
  //       event.srcElement.getAttribute('formcontrolname')
  //     ].patchValue(inputResultante.toFixed(2).replace('.', ','));
  //     this.pagosForm.controls[
  //       event.srcElement.getAttribute('formcontrolname')
  //     ].setValidators([Validators.max(importeT), Validators.min(0)]);
  //   }
  // }

  // focusTarjetaValue(event: any) {
  //   if (
  //     this.pagosForm.controls['pinpad'].value !==
  //     this.devolucionService.carritoDevolucion.precioTotal
  //   ) {
  //     this.formControlService.setCurrentFormGroup(
  //       this.pagosForm,
  //       event.srcElement,
  //       event.srcElement.getAttribute('category')
  //     );
  //     if (this.pagosForm.controls['datafono'].value) {
  //       this.pagosForm.controls['datafono'].patchValue('');
  //     }
  //     const metalicoP: number = this.pagosForm.controls['metalico'].value
  //       ? this.pagosForm.controls['metalico'].value.replace(',', '.')
  //       : 0;
  //     const transferenciaP: number = this.pagosForm.controls['transferencia']
  //       .value
  //       ? this.pagosForm.controls['transferencia'].value.replace(',', '.')
  //       : 0;
  //     const tarjetaMonederoP: number = this.pagosForm.controls['monedero'].value
  //       ? this.pagosForm.controls['monedero'].value.replace(',', '.')
  //       : 0;
  //     const aplazadoP: number = this.pagosForm.controls['aplazado'].value
  //       ? this.pagosForm.controls['aplazado'].value.replace(',', '.')
  //       : 0;
  //     const prepagoP: number = this.pagosForm.controls['prepago'].value
  //       ? this.pagosForm.controls['prepago'].value.replace(',', '.')
  //       : 0;
  //     let inputResultante =
  //       this.devolucionService.carritoDevolucion.precioTotal -
  //       this.devolucionService.carritoDevolucion.precioPrepago -
  //       transferenciaP -
  //       metalicoP -
  //       tarjetaMonederoP -
  //       aplazadoP -
  //       prepagoP;
  //     const importeT: number = this.albaranService.albaranValue.AlbaranFOPs.find(
  //       (x) => x.FOP == 'CC'
  //     )
  //       ? Number.parseFloat(
  //           this.conversor(
  //             this.albaranService.albaranValue.AlbaranFOPs.find(
  //               (x) => x.FOP == 'CC'
  //             ).ImportePendiente
  //           ).toFixed(2)
  //         )
  //       : 0;
  //     if (importeT == 0) {
  //       inputResultante = 0;
  //     } else {
  //       if (inputResultante > importeT) {
  //         inputResultante = importeT;
  //       }
  //       if (inputResultante < 0) {
  //         inputResultante = 0;
  //       }
  //     }
  //     const inputSeleccionado = this.formControlService.currentFormGroupValue.elemento.getAttribute(
  //       'formcontrolname'
  //     );
  //     this.formControlService.currentFormGroupValue.form.controls[
  //       inputSeleccionado
  //     ].patchValue(inputResultante.toFixed(2).replace('.', ','));
  //     this.formControlService.currentFormGroupValue.form.controls[
  //       inputSeleccionado
  //     ].setValidators([Validators.max(importeT), Validators.min(0)]);
  //     this.pagosForm.controls[
  //       event.srcElement.getAttribute('formcontrolname')
  //     ].patchValue(inputResultante.toFixed(2).replace('.', ','));
  //     this.pagosForm.controls[
  //       event.srcElement.getAttribute('formcontrolname')
  //     ].setValidators([Validators.max(importeT), Validators.min(0)]);
  //   }
  // }

  // focusDatafonoValue(event: any) {
  //   if (
  //     this.pagosForm.controls['datafono'].value !==
  //     this.devolucionService.carritoDevolucion.precioTotal
  //   ) {
  //     this.formControlService.setCurrentFormGroup(
  //       this.pagosForm,
  //       event.srcElement,
  //       event.srcElement.getAttribute('category')
  //     );
  //     if (this.pagosForm.controls['pinpad'].value) {
  //       this.pagosForm.controls['pinpad'].patchValue('');
  //     }
  //     const metalicoP: number = this.pagosForm.controls['metalico'].value
  //       ? this.pagosForm.controls['metalico'].value.replace(',', '.')
  //       : 0;
  //     const transferenciaP: number = this.pagosForm.controls['transferencia']
  //       .value
  //       ? this.pagosForm.controls['transferencia'].value.replace(',', '.')
  //       : 0;
  //     const tarjetaMonederoP: number = this.pagosForm.controls['monedero'].value
  //       ? this.pagosForm.controls['monedero'].value.replace(',', '.')
  //       : 0;
  //     const aplazadoP: number = this.pagosForm.controls['aplazado'].value
  //       ? this.pagosForm.controls['aplazado'].value.replace(',', '.')
  //       : 0;
  //     const prepagoP: number = this.pagosForm.controls['prepago'].value
  //       ? this.pagosForm.controls['prepago'].value.replace(',', '.')
  //       : 0;
  //     let inputResultante =
  //       this.devolucionService.carritoDevolucion.precioTotal -
  //       this.devolucionService.carritoDevolucion.precioPrepago -
  //       transferenciaP -
  //       metalicoP -
  //       tarjetaMonederoP -
  //       aplazadoP -
  //       prepagoP;
  //     const importeT: number = this.albaranService.albaranValue.AlbaranFOPs.find(
  //       (x) => x.FOP == 'CC'
  //     )
  //       ? Number.parseFloat(
  //           this.conversor(
  //             this.albaranService.albaranValue.AlbaranFOPs.find(
  //               (x) => x.FOP == 'CC'
  //             ).ImportePendiente
  //           ).toFixed(2)
  //         )
  //       : 0;
  //     if (importeT == 0) {
  //       inputResultante = 0;
  //     } else {
  //       if (inputResultante > importeT) {
  //         inputResultante = importeT;
  //       }
  //       if (inputResultante < 0) {
  //         inputResultante = 0;
  //       }
  //     }
  //     const inputSeleccionado = this.formControlService.currentFormGroupValue.elemento.getAttribute(
  //       'formcontrolname'
  //     );
  //     this.formControlService.currentFormGroupValue.form.controls[
  //       inputSeleccionado
  //     ].patchValue(inputResultante.toFixed(2).replace('.', ','));
  //     this.formControlService.currentFormGroupValue.form.controls[
  //       inputSeleccionado
  //     ].setValidators([Validators.max(importeT), Validators.min(0)]);
  //     this.pagosForm.controls[
  //       event.srcElement.getAttribute('formcontrolname')
  //     ].patchValue(inputResultante.toFixed(2).replace('.', ','));
  //     this.pagosForm.controls[
  //       event.srcElement.getAttribute('formcontrolname')
  //     ].setValidators([Validators.max(importeT), Validators.min(0)]);
  //   }
  // }

  // // yaribel 11112020 Añadimos focus de Tarjeta Monedero
  // focusTarjetaMonederoValue(event: any) {
  //   if (
  //     this.pagosForm.controls['monedero'].value !==
  //     this.devolucionService.carritoDevolucion.precioTotal
  //   ) {
  //     this.formControlService.setCurrentFormGroup(
  //       this.pagosForm,
  //       event.srcElement,
  //       event.srcElement.getAttribute('category')
  //     );
  //   }
  //   const pinpadP: number = this.pagosForm.controls['pinpad'].value
  //     ? this.pagosForm.controls['pinpad'].value.replace(',', '.')
  //     : 0;
  //   const datafonoP: number = this.pagosForm.controls['datafono'].value
  //     ? this.pagosForm.controls['datafono'].value.replace(',', '.')
  //     : 0;
  //   const metalicoP: number = this.pagosForm.controls['metalico'].value
  //     ? this.pagosForm.controls['metalico'].value.replace(',', '.')
  //     : 0;
  //   const transferenciaP: number = this.pagosForm.controls['transferencia']
  //     .value
  //     ? this.pagosForm.controls['transferencia'].value.replace(',', '.')
  //     : 0;
  //   const aplazadoP: number = this.pagosForm.controls['aplazado'].value
  //     ? this.pagosForm.controls['aplazado'].value.replace(',', '.')
  //     : 0;
  //   const prepagoP: number = this.pagosForm.controls['prepago'].value
  //     ? this.pagosForm.controls['prepago'].value.replace(',', '.')
  //     : 0;
  //   let inputResultante =
  //     this.devolucionService.carritoDevolucion.precioTotal -
  //     this.devolucionService.carritoDevolucion.precioPrepago -
  //     datafonoP -
  //     pinpadP -
  //     transferenciaP -
  //     metalicoP -
  //     aplazadoP -
  //     prepagoP;
  //   const importeT: number = this.albaranService.albaranValue.AlbaranFOPs.find(
  //     (x) => x.FOP == 'TM'
  //   )
  //     ? Number.parseFloat(
  //         this.conversor(
  //           this.albaranService.albaranValue.AlbaranFOPs.find(
  //             (x) => x.FOP == 'TM'
  //           ).ImportePendiente
  //         ).toFixed(2)
  //       )
  //     : 0;
  //   if (importeT == 0) {
  //     inputResultante = 0;
  //   } else {
  //     if (inputResultante > importeT) {
  //       inputResultante = importeT;
  //     }
  //     if (inputResultante < 0) {
  //       inputResultante = 0;
  //     }
  //   }
  //   const inputSeleccionado = this.formControlService.currentFormGroupValue.elemento.getAttribute(
  //     'formcontrolname'
  //   );
  //   this.formControlService.currentFormGroupValue.form.controls[
  //     inputSeleccionado
  //   ].patchValue(inputResultante.toFixed(2).replace('.', ','));
  //   this.formControlService.currentFormGroupValue.form.controls[
  //     inputSeleccionado
  //   ].setValidators([Validators.max(importeT), Validators.min(0)]);
  //   this.pagosForm.controls[
  //     event.srcElement.getAttribute('formcontrolname')
  //   ].patchValue(inputResultante.toFixed(2).replace('.', ','));
  //   this.pagosForm.controls[
  //     event.srcElement.getAttribute('formcontrolname')
  //   ].setValidators([Validators.max(importeT), Validators.min(0)]);
  // }

  // focusAplazadoValue(event: any) {
  //   if (
  //     this.pagosForm.controls['aplazado'].value !==
  //     this.devolucionService.carritoDevolucion.precioTotal
  //   ) {
  //     this.formControlService.setCurrentFormGroup(
  //       this.pagosForm,
  //       event.srcElement,
  //       event.srcElement.getAttribute('category')
  //     );
  //   }
  //   const pinpadP: number = this.pagosForm.controls['pinpad'].value
  //     ? this.pagosForm.controls['pinpad'].value.replace(',', '.')
  //     : 0;
  //   const datafonoP: number = this.pagosForm.controls['datafono'].value
  //     ? this.pagosForm.controls['datafono'].value.replace(',', '.')
  //     : 0;
  //   const metalicoP: number = this.pagosForm.controls['metalico'].value
  //     ? this.pagosForm.controls['metalico'].value.replace(',', '.')
  //     : 0;
  //   const transferenciaP: number = this.pagosForm.controls['transferencia']
  //     .value
  //     ? this.pagosForm.controls['transferencia'].value.replace(',', '.')
  //     : 0;
  //   const tarjetaMonederoP: number = this.pagosForm.controls['monedero'].value
  //     ? this.pagosForm.controls['monedero'].value.replace(',', '.')
  //     : 0;
  //   const prepagoP: number = this.pagosForm.controls['prepago'].value
  //     ? this.pagosForm.controls['prepago'].value.replace(',', '.')
  //     : 0;
  //   let inputResultante =
  //     this.devolucionService.carritoDevolucion.precioTotal -
  //     this.devolucionService.carritoDevolucion.precioPrepago -
  //     datafonoP -
  //     pinpadP -
  //     transferenciaP -
  //     metalicoP -
  //     tarjetaMonederoP -
  //     prepagoP;
  //   const importeT: number = this.albaranService.albaranValue.AlbaranFOPs.find(
  //     (x) => x.FOP == 'DP'
  //   )
  //     ? Number.parseFloat(
  //         this.conversor(
  //           this.albaranService.albaranValue.AlbaranFOPs.find(
  //             (x) => x.FOP == 'DP'
  //           ).ImportePendiente
  //         ).toFixed(2)
  //       )
  //     : 0;
  //   if (importeT == 0) {
  //     inputResultante = 0;
  //   } else {
  //     if (inputResultante > importeT) {
  //       inputResultante = importeT;
  //     }
  //     if (inputResultante < 0) {
  //       inputResultante = 0;
  //     }
  //   }
  //   const inputSeleccionado = this.formControlService.currentFormGroupValue.elemento.getAttribute(
  //     'formcontrolname'
  //   );
  //   this.formControlService.currentFormGroupValue.form.controls[
  //     inputSeleccionado
  //   ].patchValue(inputResultante.toFixed(2).replace('.', ','));
  //   this.formControlService.currentFormGroupValue.form.controls[
  //     inputSeleccionado
  //   ].setValidators([Validators.max(importeT), Validators.min(0)]);
  //   this.pagosForm.controls[
  //     event.srcElement.getAttribute('formcontrolname')
  //   ].patchValue(inputResultante.toFixed(2).replace('.', ','));
  //   this.pagosForm.controls[
  //     event.srcElement.getAttribute('formcontrolname')
  //   ].setValidators([Validators.max(importeT), Validators.min(0)]);
  // }

  focusTransferenciaValue(event: any) {
    if (
      !this.permisosService.puedeAccionar(
        this.user.currentUserValue,
        'VentaPagoTransferencia',
        {
          toast: false,
          elevarUsuario: true
        }
      )
    ) {
      this.usuarioElevadoService.haSidoElevado$.subscribe((elevado) => {
        if (elevado) {
          //this.cargarTransferenciaInput(event);
          this.focusValue(event);
        }
      });
    } else {
      // this.cargarTransferenciaInput(event);
      this.focusValue(event);
    }
    // tslint:disable-next-line:max-line-length
    // } else if (!this.permisosService.puedeAccionar(this.user.currentUserValue, 'VentaPagoTransferencia', {toast: false, elevarUsuario: true})) {
    //   this.cargarTransferenciaInput(event);
    // }
  }

  onBlurValue(event) {

    // 
    this.recalcularImportesDevolucion(
      this.formControlService.currentFormGroupValue.elemento.getAttribute(
        'formcontrolname'
      )
    );
  }

  // onBlurTarjetaMonederoValue(event) {
  //   let inputResultante: number = this.pagosForm.controls['monedero'].value
  //     ? this.pagosForm.controls['monedero'].value.replace(',', '.')
  //     : 0;
  //   const importeT: number = this.albaranService.albaranValue.AlbaranFOPs.find(
  //     (x) => x.FOP == 'TM'
  //   )
  //     ? Number.parseFloat(
  //         this.conversor(
  //           this.albaranService.albaranValue.AlbaranFOPs.find(
  //             (x) => x.FOP == 'TM'
  //           ).ImportePendiente
  //         ).toFixed(2)
  //       )
  //     : 0;
  //   if (importeT == 0) {
  //     inputResultante = 0;
  //   } else {
  //     if (inputResultante > importeT) {
  //       inputResultante = importeT;
  //       if (inputResultante < 0) {
  //         inputResultante = 0;
  //       }
  //     }
  //   }
  //   const inputSeleccionado = this.formControlService.currentFormGroupValue.elemento.getAttribute(
  //     'formcontrolname'
  //   );
  //   this.formControlService.currentFormGroupValue.form.controls[
  //     inputSeleccionado
  //   ].patchValue(inputResultante.toFixed(2).replace('.', ','));
  //   this.formControlService.currentFormGroupValue.form.controls[
  //     inputSeleccionado
  //   ].setValidators([Validators.max(importeT), Validators.min(0)]);
  //   this.pagosForm.controls[
  //     event.srcElement.getAttribute('formcontrolname')
  //   ].patchValue(inputResultante.toFixed(2).replace('.', ','));
  //   this.pagosForm.controls[
  //     event.srcElement.getAttribute('formcontrolname')
  //   ].setValidators([Validators.max(importeT), Validators.min(0)]);
  // }

  private cargarTransferenciaInput(event) {
    if (
      this.pagosForm.controls['transferencia'].value !==
      this.devolucionService.carritoDevolucion.precioTotal
    ) {
      this.formControlService.setCurrentFormGroup(
        this.pagosForm,
        event.srcElement,
        event.srcElement.getAttribute('category')
      );
      const metalicoP: number = this.pagosForm.controls['metalico'].value ? this.pagosForm.controls['metalico'].value.replace(',', '.') : 0;
      const pinpadP: number = this.pagosForm.controls['pinpad'].value ? this.pagosForm.controls['pinpad'].value.replace(',', '.') : 0;
      const datafonoP: number = this.pagosForm.controls['datafono'].value ? this.pagosForm.controls['datafono'].value.replace(',', '.') : 0;
      const tarjetaMonederoP: number = this.pagosForm.controls['monedero'].value ? this.pagosForm.controls['monedero'].value.replace(',', '.') : 0;
      const aplazadoP: number = this.pagosForm.controls['aplazado'].value ? this.pagosForm.controls['aplazado'].value.replace(',', '.') : 0;
      const prepagoP: number = this.pagosForm.controls['prepago'].value ? this.pagosForm.controls['prepago'].value.replace(',', '.') : 0;
      let inputResultante: number =
        this.devolucionService.carritoDevolucion.precioTotal -
        this.devolucionService.carritoDevolucion.precioPrepago - datafonoP - pinpadP - metalicoP - tarjetaMonederoP - aplazadoP - prepagoP;
      // 
      const inputSeleccionado = this.formControlService.currentFormGroupValue.elemento.getAttribute(
        'formcontrolname'
      );
      const importeT: number = this.albaranService.albaranValue.AlbaranFOPs.find(x => x.FOP == 'TR') ?
        Number.parseFloat(this.conversor(this.albaranService.albaranValue.AlbaranFOPs.find(x => x.FOP == 'TR').ImportePendiente).toFixed(2)) : 0;
      if (importeT == 0) {
        inputResultante = 0;
      } else {
        if (inputResultante > importeT) {
          inputResultante = importeT;
        }
        if (inputResultante < 0) {
          inputResultante = 0;
        }
      }
      this.formControlService.currentFormGroupValue.form.controls[
        inputSeleccionado
        ].patchValue(inputResultante.toFixed(2).replace('.', ','));
      this.formControlService.currentFormGroupValue.form.controls[
        inputSeleccionado
        ].setValidators([Validators.max(importeT), Validators.min(0)]);
      this.pagosForm.controls[
        event.srcElement.getAttribute('formcontrolname')
        ].patchValue(inputResultante.toFixed(2).replace('.', ','));
      this.pagosForm.controls[
        event.srcElement.getAttribute('formcontrolname')
        ].setValidators([Validators.max(importeT), Validators.min(0)]);
    }
  }

  conversor(precio: string) {
    if (!precio) {
      return;
    }
    if (typeof precio !== 'string') {
      return precio;
    }
    return Number.parseFloat(precio.replace(/,/, '.'));
  }

  isSelected(element) {
    return element && this.formControlService.currentFormGroupValue &&
    this.formControlService.currentFormGroupValue.elemento &&
    element.id === this.formControlService.currentFormGroupValue.elemento.id
      ? true
      : false;
  }

  formaDePagoInternet() {
    /*     if (this.venta.clienteSeleccionadoValue) {
      return this.venta.clienteSeleccionadoValue.FP_Id === '7' ? true : false;
    }
    return false; */
    return this.devolucionService.carritoDevolucion.precioPrepago;
  }

  tienePermiso(accion: string) {
    const conf = {
      toast: false,
      elevarUsuario: false
    };
    return this.permisosService.puedeAccionar(
      this.user.currentUserValue,
      accion,
      conf
    );
  }

  InicializarFormaPagoOrigen(PagoTotal, FormasPago: AlbaranFOP[]) {
    let importe = 0;
    let importeResultante = 0;
    let fPago = '';
    let formControl = '';
    if (PagoTotal > 0) {

      if (FormasPago.find((x) => x.FOP == 'CA') && parseFloat(this.conversor(FormasPago.find((x) => x.FOP == 'CA').ImportePendiente).toFixed(2)) > 0) {
        fPago = 'CA';
        formControl = 'metalico';
      } else if (FormasPago.find((x) => x.FOP == 'CC') &&
        parseFloat(
          this.conversor(
            FormasPago.find((x) => x.FOP == 'CC').ImportePendiente
          ).toFixed(2)
        ) > 0
      ) {
        fPago = 'CC';
        formControl = this.albaranService.albaranValue.PagoCCInfo
          .TieneLectorElectronico == 'True'
          ? 'pinpad'
          : 'datafono';
      } else if (
        FormasPago.find((x) => x.FOP == 'TM') &&
        parseFloat(
          this.conversor(
            FormasPago.find((x) => x.FOP == 'TM').ImportePendiente
          ).toFixed(2)
        ) > 0
      ) {
        fPago = 'TM';
        formControl = 'monedero';
      } else if (
        FormasPago.find((x) => x.FOP == 'TR') &&
        parseFloat(
          this.conversor(
            FormasPago.find((x) => x.FOP == 'TR').ImportePendiente
          ).toFixed(2)
        ) > 0
      ) {
        fPago = 'TR';
        formControl = 'transferencia';
      } else if (
        FormasPago.find((x) => x.FOP == 'DP') &&
        parseFloat(
          this.conversor(
            FormasPago.find((x) => x.FOP == 'DP').ImportePendiente
          ).toFixed(2)
        ) > 0
      ) {
        fPago = 'DP';
        formControl = 'aplazado';
      } else if (FormasPago.find((x) => x.FOP == 'PR') && parseFloat(this.conversor(
          FormasPago.find((x) => x.FOP == 'PR').ImportePendiente
        ).toFixed(2)
      ) > 0
      ) {
        //Yaribel 20201217 añadimos forma de pago Prepago
        fPago = 'PR';
        formControl = 'prepago';
      }

      //TODO Yaribel 20201126 evaluamos la forma de pago por defecto del cliente
      if (
        this.albaranService.albaranValue.ClienteId &&
        this.conversor(this.albaranService.albaranValue.ClienteId) > 0
      ) {
        if (
          this.albaranService.albaranValue.AlbaranCliente &&
          this.albaranService.albaranValue.AlbaranCliente.length > 0 &&
          this.albaranService.albaranValue.AlbaranCliente[0].FP_FOP
        ) {
          const FOP = this.albaranService.albaranValue.AlbaranCliente[0].FP_FOP;
          if (
            FormasPago.find((x) => x.FOP == FOP) &&
            parseFloat(
              this.conversor(
                FormasPago.find((x) => x.FOP == FOP).ImportePendiente
              ).toFixed(2)
            ) > 0
          ) {
            fPago = FOP;
          } else if (
            this.albaranService.albaranValue.AlbaranCliente[0].FP_FOP1
          ) {
            const FOP = this.albaranService.albaranValue.AlbaranCliente[0]
              .FP_FOP1;
            if (
              FormasPago.find((x) => x.FOP == FOP) &&
              parseFloat(
                this.conversor(
                  FormasPago.find((x) => x.FOP == FOP).ImportePendiente
                ).toFixed(2)
              ) > 0
            ) {
              fPago = FOP;
            }
          }
          switch (fPago) {
            case 'DP':
              formControl = 'aplazado';
              break;
            case 'CA':
              formControl = 'metalico';
              break;
            case 'CC':
              formControl = this.albaranService.albaranValue.PagoCCInfo.TieneLectorElectronico == 'True' ? 'pinpad' : 'datafono';
              break;
            case 'TM':
              formControl = 'monedero';
              break;
            case 'TR':
              formControl = 'transferencia';
              break;
            case 'PR':
              formControl = 'prepago'; //Yaribel 20201217 añadimos forma de pago Prepago
              break;
          }
        }
      }

      if (fPago != '') {
        if (fPago === 'PR') {
          this.toastService.info(
            'La devolución no puede superar el importe ' +
            (+this.albaranService.albaranValue.TotalPagar -
              +this.conversor(
                this.albaranService.albaranValue.AlbaranFOPs.find(
                  (x) => x.FOP == 'PR'
                ).ImportePendiente
              ).toFixed(2)) +
            '€. Avise al encargado para realizar la devolución del Prepago',
            'Info',
            {
              toastComponent: InfoToast,
              timeOut: 7500
            }
          );
        }
        importe = FormasPago.find((x) => x.FOP == fPago)
          ? Number.parseFloat(
            this.conversor(
              FormasPago.find((x) => x.FOP == fPago).ImportePendiente
            ).toFixed(2)
          )
          : 0;
        importeResultante = PagoTotal > importe ? importe : PagoTotal;
        this.pagosForm.controls[formControl].patchValue(
          importeResultante.toFixed(2).replace('.', ',')
        );
        if (importeResultante === PagoTotal) {
          return;
        } else {
          PagoTotal -= importeResultante;
          FormasPago.find((x) => x.FOP == fPago).ImportePendiente = '0';
          this.InicializarFormaPagoOrigen(PagoTotal, FormasPago);
        }
      }
    }
  }

  recalcularImportesDevolucion(inputSeleccionado: string) {
    // obtenemos el formcontrol modificado
    if (this.formControlService.currentFormGroupValue.elemento) {
      inputSeleccionado = this.formControlService.currentFormGroupValue.elemento.getAttribute(
        'formcontrolname'
      );
    }
    let hayCambios = false;
    let superaImporte = false;
    //precio total a devolver
    let precioTotal = this.devolucionService.carritoDevolucion.precioTotal;

    //obtenemos el maximo valor a devolver en cada forma de pago
    const importeC: number = this.albaranService.albaranValue.AlbaranFOPs.find(
      (x) => x.FOP == 'CC'
    )
      ? Number.parseFloat(
        this.conversor(
          this.albaranService.albaranValue.AlbaranFOPs.find(
            (x) => x.FOP == 'CC'
          ).ImportePendiente
        ).toFixed(2)
      )
      : 0;
    const importeT: number = this.albaranService.albaranValue.AlbaranFOPs.find(
      (x) => x.FOP == 'TR'
    )
      ? Number.parseFloat(
        this.conversor(
          this.albaranService.albaranValue.AlbaranFOPs.find(
            (x) => x.FOP == 'TR'
          ).ImportePendiente
        ).toFixed(2)
      )
      : 0;
    const importeM: number = this.albaranService.albaranValue.AlbaranFOPs.find(
      (x) => x.FOP == 'CA'
    )
      ? Number.parseFloat(
        this.conversor(
          this.albaranService.albaranValue.AlbaranFOPs.find(
            (x) => x.FOP == 'CA'
          ).ImportePendiente
        ).toFixed(2)
      )
      : 0;
    const importeTM: number = this.albaranService.albaranValue.AlbaranFOPs.find(
      (x) => x.FOP == 'TM'
    )
      ? Number.parseFloat(
        this.conversor(
          this.albaranService.albaranValue.AlbaranFOPs.find(
            (x) => x.FOP == 'TM'
          ).ImportePendiente
        ).toFixed(2)
      )
      : 0;
    const importeDP: number = this.albaranService.albaranValue.AlbaranFOPs.find(
      (x) => x.FOP == 'DP'
    )
      ? Number.parseFloat(
        this.conversor(
          this.albaranService.albaranValue.AlbaranFOPs.find(
            (x) => x.FOP == 'DP'
          ).ImportePendiente
        ).toFixed(2)
      )
      : 0;
    const importePR: number = this.albaranService.albaranValue.AlbaranFOPs.find(
      (x) => x.FOP == 'PR'
    )
      ? Number.parseFloat(
        this.conversor(
          this.albaranService.albaranValue.AlbaranFOPs.find(
            (x) => x.FOP == 'PR'
          ).ImportePendiente
        ).toFixed(2)
      )
      : 0;

    //obtenemos el importe introducido en las formas de pago
    let efectivoP: number = this.pagosForm.controls['metalico'].value
      ? +this.pagosForm.controls['metalico'].value.replace(',', '.')
      : 0;
    let pinpadP: number =
      this.pagosForm.controls['pinpad'].value &&
      inputSeleccionado !== 'datafono'
        ? +this.pagosForm.controls['pinpad'].value.replace(',', '.')
        : 0;
    let datafonoP: number =
      this.pagosForm.controls['datafono'].value &&
      inputSeleccionado !== 'pinpad'
        ? +this.pagosForm.controls['datafono'].value.replace(',', '.')
        : 0;
    let tarjetaMonederoP: number = this.pagosForm.controls['monedero'].value
      ? +this.pagosForm.controls['monedero'].value.replace(',', '.')
      : 0;
    let aplazadoP: number = this.pagosForm.controls['aplazado'].value
      ? +this.pagosForm.controls['aplazado'].value.replace(',', '.')
      : 0;
    let transferenciaP: number = this.pagosForm.controls['transferencia'].value
      ? +this.pagosForm.controls['transferencia'].value.replace(',', '.')
      : 0;
    let prepagoP: number = this.pagosForm.controls['prepago'].value
      ? +this.pagosForm.controls['prepago'].value.replace(',', '.')
      : 0;

    //Comprobamos si el importe introducido es mayor al importe total a devolver
    switch (inputSeleccionado) {
      case 'transferencia':
        if (transferenciaP > importeT) {
          transferenciaP = importeT;
          hayCambios = true;
          superaImporte = true;
        }
        break;
      case 'datafono':
      case 'pinpad':
        if (datafonoP > importeC) {
          datafonoP = importeC;
          hayCambios = true;
          superaImporte = true;
        } else if (pinpadP > importeC) {
          pinpadP = importeC;
          hayCambios = true;
          superaImporte = true;
        }
        break;
      case 'monedero':
        if (tarjetaMonederoP > importeTM) {
          tarjetaMonederoP = importeTM;
          hayCambios = true;
          superaImporte = true;
        }
      case 'aplazado':
        if (aplazadoP > importeDP) {
          aplazadoP = importeDP;
          hayCambios = true;
          superaImporte = true;
        }
        break;
      case 'metalico':
        if (efectivoP > importeM) {
          efectivoP = importeM;
          hayCambios = true;
          superaImporte = true;
        }
        break;

    }

    if (this.albaranService.albaranValue.AlbaranFOPs.length > 1) {
      let inputResultante: number =
        precioTotal -
        prepagoP -
        datafonoP -
        pinpadP -
        tarjetaMonederoP -
        transferenciaP -
        aplazadoP -
        efectivoP;
      if (inputResultante < 0) {
        if (
          inputResultante < 0 &&
          transferenciaP > 0 &&
          inputSeleccionado !== 'transferencia'
        ) {
          if (inputResultante < -transferenciaP) {
            inputResultante = inputResultante + transferenciaP;
            transferenciaP = 0;
          } else {
            transferenciaP = transferenciaP + inputResultante;
            inputResultante = 0;
          }
          hayCambios = true;
        }
        if (
          inputResultante < 0 &&
          tarjetaMonederoP > 0 &&
          inputSeleccionado !== 'monedero'
        ) {
          if (inputResultante < -tarjetaMonederoP) {
            inputResultante = inputResultante + tarjetaMonederoP;
            tarjetaMonederoP = 0;
          } else {
            tarjetaMonederoP = tarjetaMonederoP + inputResultante;
            inputResultante = 0;
          }
          hayCambios = true;
        }
        if (
          inputResultante < 0 &&
          datafonoP > 0 &&
          inputSeleccionado !== 'datafono'
        ) {
          if (inputResultante < -datafonoP) {
            inputResultante = inputResultante + datafonoP;
            datafonoP = 0;
          } else {
            datafonoP = datafonoP + inputResultante;
            inputResultante = 0;
          }
          hayCambios = true;
        }
        if (
          inputResultante < 0 &&
          pinpadP > 0 &&
          inputSeleccionado !== 'pinpad'
        ) {
          if (inputResultante < -pinpadP) {
            inputResultante = inputResultante + pinpadP;
            pinpadP = 0;
          } else {
            pinpadP = pinpadP + inputResultante;
            inputResultante = 0;
          }
          hayCambios = true;
        }
        if (
          inputResultante < 0 &&
          aplazadoP > 0 &&
          inputSeleccionado !== 'aplazado'
        ) {
          if (inputResultante < -aplazadoP) {
            inputResultante = inputResultante + aplazadoP;
            aplazadoP = 0;
          } else {
            aplazadoP = aplazadoP + inputResultante;
            inputResultante = 0;
          }
          hayCambios = true;
        }
        if (
          inputResultante < 0 &&
          efectivoP > 0 &&
          inputSeleccionado !== 'metalico'
        ) {
          if (inputResultante < -efectivoP) {
            inputResultante = inputResultante + efectivoP;
            efectivoP = 0;
          } else {
            efectivoP = efectivoP + inputResultante;
            inputResultante = 0;
          }
          hayCambios = true;
        }
      }
    }
    if (superaImporte) {
      this.toastService.info('', `${this.translate.instant('SUPERA_IMPORTE_MAXIMO')} ` + (inputSeleccionado === 'monedero' ? this.translate.instant('TARJETA_MONEDERO') : inputSeleccionado) + '.', {
        toastComponent: InfoToast,
        timeOut: 5000
      });
    }
    if (hayCambios) {
      this.pagosForm.patchValue({
        pinpad: pinpadP.toFixed(2).toString().replace('.', ','),
        datafono: datafonoP.toFixed(2).toString().replace('.', ','),
        transferencia: transferenciaP.toFixed(2).toString().replace('.', ','),
        monedero: tarjetaMonederoP.toFixed(2).toString().replace('.', ','),
        aplazado: aplazadoP.toFixed(2).toString().replace('.', ','),
        metalico: efectivoP.toFixed(2).toString().replace('.', ',')
      });
    }
  }
}
